import Swal from "sweetalert2"
import errorIcon from "@/assets/errorIcon.svg"
import styles from "./swalFires.module.scss"

export const notificationAlertPopup = () => {
    Swal.fire({
        html:
            `<img alt='errorIcon' src=${errorIcon} />` +
            "<h3>通知設定を変更できません</h3>" +
            "<p>「設定」アプリにある「御朱印巡り」<br/>の通知設定を変更してください</p>" +
            `<button class=${styles.closeButton} id='notificationAlertPopup_closeButton'>閉じる</button>`,
        showConfirmButton: false,
        allowOutsideClick: false,
        customClass: {
            popup: styles.notificationAlertPopup,
            htmlContainer: styles.htmlContainer,
        },
        didOpen: () => {
            const closeButton = document.getElementById("notificationAlertPopup_closeButton")
            closeButton!.onclick = () => {
                Swal.close()
            }
        },
    })
}
