import Swal from "sweetalert2"
import errorIcon from "@/assets/errorIcon.svg"
import styles from "./swalFires.module.scss"

export const noSignalPopup = () => {
    Swal.fire({
        html:
            `<img alt="errorIcon" src=${errorIcon} />` +
            `<h3>位置情報を取得できません</h3>` +
            `<p>電波状況を確認してください</p>` +
            `<button class=${styles.closeButton} id="noSignalPopup_closeButton">閉じる</button>`,
        showConfirmButton: false,
        allowOutsideClick: false,
        customClass: {
            popup: styles.noSignalPopup,
            htmlContainer: styles.htmlContainer,
        },
        didOpen: () => {
            const closeButton = document.getElementById("noSignalPopup_closeButton")
            closeButton!.onclick = () => {
                Swal.close()
            }
        },
    })
}
