import { getHistoryOfVisits, HistoryOfVisit } from "@/repositories/historyOfVisitRepository"
import { createDateFormat } from "@/functions/createDate"

export type HistoryOfVisitGroupByDate = {
    visitDate: number
    jinjaList: HistoryOfVisitGroupChild[]
}

export type HistoryOfVisitGroupChild = {
    jinjaName: string
    jinjaLastWord: string
    jinjaId: string
    historyOfVisitId: string
}

export const historyOfVisitsSort = async (): Promise<HistoryOfVisitGroupByDate[]> => {
    const historyOfVisitEntities: HistoryOfVisit[] = await getHistoryOfVisits()

    const dateOfVisitList: string[] = historyOfVisitEntities.map(
        (historyOfVisitEntity: HistoryOfVisit) => createDateFormat(historyOfVisitEntity.visitDate)
    )
    const filteredDateOfVisitList: string[] = Array.from(new Set(dateOfVisitList))

    return filteredDateOfVisitList.map((filteredDateOfVisit) => {
        const matchHistoryOfVisitEntities = historyOfVisitEntities.filter(
            (historyOfVisitEntity) =>
                filteredDateOfVisit === createDateFormat(historyOfVisitEntity.visitDate)
        )
        const historyOfVisit: HistoryOfVisitGroupChild[] = matchHistoryOfVisitEntities.map(
            (matchHistoryOfVisitEntity) => ({
                jinjaName: matchHistoryOfVisitEntity.jinjaName,
                jinjaLastWord: matchHistoryOfVisitEntity.jinjaLastWord,
                jinjaId: matchHistoryOfVisitEntity.jinjaId,
                historyOfVisitId: matchHistoryOfVisitEntity.historyOfVisitId,
            })
        )
        return {
            visitDate: new Date(filteredDateOfVisit).getTime(),
            jinjaList: historyOfVisit,
        }
    })
}
