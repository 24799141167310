import { withToastPopup } from "../enhance/withToastPopup"
import upArrow from "@/assets/myPage/upArrow.svg"
import { useContext, useEffect, useRef, useState } from "react"
import styles from "./QuestionView.module.scss"
import { ApplicationContext } from "../contexts/ApplicationContextProvider"

type QuestionViewProps = {
    setIsShowPopup: (compareStartWithEnd: boolean) => void
    isQuestion: boolean
}

export const QuestionView = () => {
    const { isShowAnswer1, setIsShowAnswer1, isShowAnswer2, setIsShowAnswer2 } =
        useContext(ApplicationContext)!
    const [isShowAnswer3, setIsShowAnswer3] = useState(false)
    const [isShowAnswer4, setIsShowAnswer4] = useState(false)
    const [isShowAnswer5, setIsShowAnswer5] = useState(false)
    const questionAreaRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const scrollTarget = window.sessionStorage.getItem("questionView_scrollPosition")
        if (scrollTarget) {
            scrollToTarget(Number(scrollTarget))
            window.sessionStorage.removeItem("questionView_scrollPosition")
        }
    }, [])

    const scrollToTarget = (scrollTarget: number) => {
        const marginTop = 16
        const questionTitleAreaHeight = 64
        const scrollPositionY = marginTop + questionTitleAreaHeight * scrollTarget
        questionAreaRef.current?.parentElement?.scrollTo({
            top: scrollPositionY,
            behavior: "smooth",
        })
    }

    return (
        <div className={styles.questionArea} ref={questionAreaRef}>
            <hr />
            <div
                className={styles.questionTitleArea}
                onClick={() => {
                    setIsShowAnswer1((prev) => !prev)
                }}
            >
                <p>Q iPhoneでチェックイン出来ない</p>
                <img
                    src={upArrow}
                    alt="upArrow"
                    className={isShowAnswer1 ? styles.downArrowStyle : styles.upArrowStyle}
                />
            </div>
            {isShowAnswer1 && (
                <div className={styles.answerArea}>
                    <div>
                        <p>A</p>
                        <p>
                            「チェックイン」ボタンを使用し、参拝履歴をアプリへ保存するにはスマートフォンの位置情報を使用するため、位置情報の設定を有効化する必要があります
                            <br />
                            「設定」アプリより、下記設定を以下のように変更お願いします
                        </p>
                    </div>
                    <div>
                        <div>
                            <p>1.</p>
                            <p>
                                {"「設定」 > 「プライバシーとセキュリティ」 > 「位置情報サービス」"}
                            </p>
                        </div>
                        <p>1）一番上の位置情報サービスをONにする</p>
                        <p>
                            2）「SafariのWebサイト」
                            ※をタップし、「このアプリの使用中」にチェックを入れる
                        </p>
                    </div>
                    <div>
                        <div>
                            <p>2.</p>
                            <p>{"「設定」 > 「Safari」 ※ > 「位置情報」"}</p>
                        </div>
                        <p>
                            1）「位置情報のアクセス」で「meguri.toyota」が「拒否」で設定されている場合は、「確認」または「許可」にチェックを入れる
                        </p>
                        <p>もしくは</p>
                        <p>
                            2）「すべてのWEBサイトで位置情報へのアクセス」を「確認」または「許可」にチェックを入れる
                        </p>
                    </div>
                    <p>
                        ※
                        Safari以外のブラウザ（Chrome等）からアプリをご利用中の場合も「SafariのWebサイト」の設定を変更してください
                    </p>
                </div>
            )}
            <hr />
            <div
                className={styles.questionTitleArea}
                onClick={() => {
                    setIsShowAnswer2((prev) => !prev)
                }}
            >
                <p>Q Androidでチェックイン出来ない</p>
                <img
                    src={upArrow}
                    alt="upArrow"
                    className={isShowAnswer2 ? styles.downArrowStyle : styles.upArrowStyle}
                />
            </div>
            {isShowAnswer2 && (
                <div className={styles.answerArea}>
                    <div>
                        <p>A</p>
                        <p>
                            「チェックイン」ボタンを使用し、参拝履歴をアプリへ保存するにはスマートフォンの位置情報を使用するため、位置情報の設定を有効化する必要があります
                            <br />
                            「設定」アプリにある下記設定を以下のように変更お願いします
                        </p>
                    </div>
                    <div>
                        <div>
                            <p>1.</p>
                            <div>
                                <p>{"「設定」アプリを開く > 「位置情報」"}</p>
                                <p>{"「位置情報を使用」をONにする"}</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <p>2.</p>
                            <div>
                                <p>
                                    {"「設定」アプリを開く > 「位置情報」 > 「Chrome」アプリを選択"}
                                </p>
                                <p>
                                    {
                                        "「位置情報の権限」を「アプリの使用中のみ許可」もしくは「毎回確認する」にチェックを入れる"
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <p>3.</p>
                            <p>
                                {
                                    "「Chrome」アプリを開く > 「メニューアイコン」 > 「設定」 > 「サイトの設定」 > 「位置情報」「ブロック」に「https://meguri.toyota」が設定されていた場合は、「許可」へ変更する"
                                }
                            </p>
                        </div>
                    </div>
                </div>
            )}
            <hr />
            <div
                className={styles.questionTitleArea}
                onClick={() => {
                    setIsShowAnswer3((prev) => !prev)
                }}
            >
                <p>Q 神社で御朱印を受けられなかった</p>
                <img
                    src={upArrow}
                    alt="upArrow"
                    className={isShowAnswer3 ? styles.downArrowStyle : styles.upArrowStyle}
                />
            </div>
            {isShowAnswer3 && (
                <div className={styles.answerArea}>
                    <div>
                        <p>A</p>
                        <p>
                            このたびは神社に足を運んでいただきありがとうございます
                            <br />
                            <br />
                            御朱印が受けられなかった旨大変残念ではございますが、今回御朱印帳に掲載している神社は無人のところも多く、神職の方々が複数社を兼務されている箇所も多数ございます
                            <br />
                            <br />
                            事前にお電話などで在社日を確認いただくことで、お受けできる可能性は高まるかと思いますが、あくまで参拝の記念としてお受けいただき、ご縁があったというお気持ちで臨んでいただけますと幸甚です
                        </p>
                    </div>
                </div>
            )}
            <hr />
            <div
                className={styles.questionTitleArea}
                onClick={() => {
                    setIsShowAnswer4((prev) => !prev)
                }}
            >
                <p>Q バッジを集めると特典はあるか</p>
                <img
                    src={upArrow}
                    alt="upArrow"
                    className={isShowAnswer4 ? styles.downArrowStyle : styles.upArrowStyle}
                />
            </div>
            {isShowAnswer4 && (
                <div className={styles.answerArea}>
                    <div>
                        <p>A</p>
                        <p>
                            バッジ機能は参拝やアプリの利用で得られる記念体験として機能を提供しております
                            <br />
                            <br />
                            現状特典は用意しておりませんが、将来的なサービスの検討にあたりバッジ等を集めることで特典やインセンティブの提供を検討しておりますので、どのような特典があれば嬉しいか等、是非みなさまのご意見をいただけますと幸いです
                            <br />
                            （ご意見・ご要望はマイページにある「
                            <a
                                href={
                                    "https://forms.office.com/Pages/ResponsePage.aspx?id=XjPB0YL1qUK2_l4aFuubyKO8uAY1t1hOtz0Gb5mCBeFUOTVaMVpMT1JEVk9XQVRRODk5UVRLRFJXQy4u"
                                }
                                target={"_blank"}
                            >
                                お問い合わせ
                            </a>
                            」よりお願いいたします)
                        </p>
                    </div>
                </div>
            )}
            <hr />
            <div
                className={styles.questionTitleArea}
                onClick={() => {
                    setIsShowAnswer5((prev) => !prev)
                }}
            >
                <p>Q 御朱印帳を追加で購入したい</p>
                <img
                    src={upArrow}
                    alt="upArrow"
                    className={isShowAnswer5 ? styles.downArrowStyle : styles.upArrowStyle}
                />
            </div>
            {isShowAnswer5 && (
                <div className={styles.answerArea}>
                    <div>
                        <p>A</p>
                        <p>
                            このたびは「倭姫命の御朱印巡り」御朱印帳をお求めいただきありがとうございます
                            <br />
                            <br />
                            追加分の頒布につきましては、下記の神社・協力店で販売を予定しております
                            <br />
                            詳細については販売店までお問い合わせをいただきますようお願いいたします
                            <br />
                            <br />
                            ＜販売予定先＞
                            <br />
                            大阪府：奥の天神 生根神社（大阪市住吉区）
                            <br />
                            東京都：ISEMISE（千代田区）
                            <br />
                            奈良県：阿紀神社（宇陀市）
                            <br />
                            三重県：おかげ横丁（内宮）、伊勢菊一（外宮）、
                            <a href="https://comise.base.shop/items/90881637" target="_blank">
                                COMISE（外宮）
                            </a>
                            、神館神社（桑名市）
                            <br />
                            ※在庫状況は各販売先にお問い合わせください
                        </p>
                    </div>
                </div>
            )}
            <hr />
        </div>
    )
}

export const QuestionViewWithToastPopup = withToastPopup<QuestionViewProps>(QuestionView)
