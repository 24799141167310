import React, { useContext, useState } from "react"
import Cropper, { Area, MediaSize } from "react-easy-crop"
import { getAndSaveCroppedImg } from "../functions/getAndSaveCroppedImg"
import styles from "./CropperModal.module.scss"
import { ApplicationContext } from "../contexts/ApplicationContextProvider"

export type CropperModalProps = {
    setIsOpenModal: (isOpen: boolean) => void
    dataURL: string
    userImageInputRef: React.RefObject<HTMLInputElement>
}

export const CROP_WIDTH = 200
export const ASPECT_RATIO = 1
export const CropperModal = (props: CropperModalProps) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [minZoom, setMinZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>()
    const { setUserIcon } = useContext(ApplicationContext)!

    const onMediaLoaded = (mediaSize: MediaSize) => {
        const { width, height } = mediaSize
        if (width > height) {
            const scale = CROP_WIDTH / height
            setZoom(scale)
            setMinZoom(scale)
        } else {
            const scale = CROP_WIDTH / width
            setZoom(scale)
            setMinZoom(scale)
        }
    }

    return (
        <div>
            <div className={styles.modalBackground}>
                <div className={styles.cropperModalArea}>
                    <div className={styles.buttonArea}>
                        <button
                            className={styles.cancelButton}
                            onClick={() => {
                                props.setIsOpenModal(false)
                                props.userImageInputRef.current!.value = ""
                            }}
                        >
                            キャンセル
                        </button>
                        <button
                            className={styles.saveButton}
                            onClick={async () => {
                                props.setIsOpenModal(false)
                                props.userImageInputRef.current!.value = ""
                                if (croppedAreaPixels) {
                                    await getAndSaveCroppedImg(props.dataURL, croppedAreaPixels)
                                        .then((res) => {
                                            if (res) setUserIcon(res.getUrl)
                                        })
                                        .catch((error) => console.log(error))
                                }
                            }}
                        >
                            保存
                        </button>
                    </div>
                    <Cropper
                        classes={{
                            containerClassName: styles.containerArea,
                            mediaClassName: styles.cropperImage,
                        }}
                        image={props.dataURL}
                        crop={crop}
                        zoom={zoom}
                        minZoom={minZoom}
                        maxZoom={minZoom + 3}
                        aspect={ASPECT_RATIO}
                        onCropChange={setCrop}
                        onCropComplete={(_, croppedAreaPixels: Area) =>
                            setCroppedAreaPixels(croppedAreaPixels)
                        }
                        onZoomChange={setZoom}
                        cropSize={{
                            width: CROP_WIDTH,
                            height: CROP_WIDTH / ASPECT_RATIO,
                        }}
                        cropShape="round"
                        onMediaLoaded={onMediaLoaded}
                        showGrid={false}
                    />
                </div>
            </div>
        </div>
    )
}
