import { axiosGet } from "../clients/httpClient"
import { AxiosResponse } from "axios"

export type ResponseGetPicture = {
    pictureId: string
    registrationDate: number
    visitDate: number
    jinjaId: string
    fileName: string
    historyOfVisitId: string
    getUrl: string
    singlePhotoGetUrl: string
    jinjaName: string
    jinjaLastWord: string
    publishFlag: boolean
    userName: string
    userIcon: string
}

export type ResponsePutUserPicture = {
    fileName: string
    registrationDate: number
    putUrl: string
    originalPutUrl: string
    singlePhotoPutUrl: string
    getUrl: string
    singlePhotoGetUrl: string
}

export type ResponseDeleteUserPicture = {
    deleteUrl: string
    deleteSinglePhotoUrl: string
    deleteOriginalUrl: string
}

export type ResponseGetIcon = {
    getUrl: string
}

export type ResponsePutIcon = {
    getUrl: string
    putUrl: string
}

export const getPreSignedUrlObjectsForGetJinjaPictures = async (
    jinjaId: string
): Promise<ResponseGetPicture[]> => {
    const { data } = (await axiosGet("/api/pre-signed-urls/get-jinja-pictures", {
        params: {
            jinjaId: jinjaId,
        },
    })) as AxiosResponse
    return data
}

export const getPreSignedUrlObjectsForGetAllUserPublishPictures = async (
    jinjaId: string
): Promise<ResponseGetPicture[]> => {
    const { data } = (await axiosGet("/api/pre-signed-urls/get-all-user-publish-pictures", {
        params: {
            jinjaId: jinjaId,
        },
    })) as AxiosResponse
    return data
}

export const getPreSignedUrlObjectForGetUserIcon = async (): Promise<ResponseGetIcon> => {
    const { data } = (await axiosGet("/api/pre-signed-urls/get-user-icon")) as AxiosResponse
    return data
}

export const getPreSignedUrlObjectForPutUserIcon = async (): Promise<ResponsePutIcon> => {
    const { data } = (await axiosGet("/api/pre-signed-urls/put-user-icon")) as AxiosResponse
    return data
}

export const getPreSignedUrlObjectsForGetUserPictures = async (): Promise<ResponseGetPicture[]> => {
    const { data } = (await axiosGet("/api/pre-signed-urls/get-user-pictures")) as AxiosResponse
    return data
}

export const getPreSignedUrlObjectForPutUserPicture = async (
    fileType: string
): Promise<ResponsePutUserPicture> => {
    const { data } = (await axiosGet("/api/pre-signed-urls/put-user-picture", {
        params: {
            fileType: fileType,
        },
    })) as AxiosResponse
    return data
}

export const getPreSignedUrlObjectForDeleteUserPicture = async (
    pictureId: string
): Promise<ResponseDeleteUserPicture> => {
    const { data } = (await axiosGet("/api/pre-signed-urls/delete-user-picture", {
        params: {
            pictureId: pictureId,
        },
    })) as AxiosResponse
    return data
}
