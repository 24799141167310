import Swal from "sweetalert2"
import errorIcon from "@/assets/errorIcon.svg"
import styles from "@/functions/swalPopups/swalFires.module.scss"
import { NavigateFunction } from "react-router-dom"
import { isIphone } from "@/functions/isIphone"

export const geolocationError1Popup = (
    navigate: NavigateFunction,
    setIsShowQuestionPopup: React.Dispatch<React.SetStateAction<boolean>>,
    setIsShowAnswer1: React.Dispatch<React.SetStateAction<boolean>>,
    setIsShowAnswer2: React.Dispatch<React.SetStateAction<boolean>>
) => {
    Swal.fire({
        html:
            `<img alt='errorIcon' src=${errorIcon} />` +
            "<h3>位置情報を取得できません</h3>" +
            "<p>「よくある質問」の「チェックイン<br />出来ない」を確認してください</p>" +
            "<div>" +
            `<button class=${styles.closeButton} id='geolocationError1Popup_closeButton'>閉じる</button>` +
            `<button class=${styles.confirmButton} id='geolocationError1Popup_confirmButton'>確認する</button>` +
            "</div>",
        showConfirmButton: false,
        allowOutsideClick: false,
        customClass: {
            popup: styles.geolocationError1Popup,
            htmlContainer: styles.htmlContainer,
        },
        didOpen: () => {
            const closeButton = document.getElementById("geolocationError1Popup_closeButton")
            closeButton!.onclick = () => {
                Swal.close()
            }
            const confirmButton = document.getElementById("geolocationError1Popup_confirmButton")
            confirmButton!.onclick = () => {
                Swal.close()
                navigate(`/app/mypage`)
                setIsShowQuestionPopup(true)
                if (isIphone()) {
                    setIsShowAnswer1(true)
                    sessionStorage.setItem("questionView_scrollPosition", "0")
                } else {
                    setIsShowAnswer2(true)
                    sessionStorage.setItem("questionView_scrollPosition", "1")
                }
            }
        },
    })
}
