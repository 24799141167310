import { EventInfo } from "@/repositories/jinjaRepository"

export const eventBuilder = (value: Partial<EventInfo>): EventInfo => {
    return {
        name: "",
        details: "",
        month: 1,
        date: 1,
        time: "",
        ...value,
    }
}
