import { InstallButton } from "@/components/InstallButton"
import styles from "./DownloadPage.module.scss"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { isIphone } from "@/functions/isIphone"

export const DownloadPage = () => {
    const navigate = useNavigate()
    const [isInstallable, setInstallable] = useState(false)

    useEffect(() => {
        if (isIphone()) {
            navigate("/app/top")
        }
    }, [])
    return (
        <div className={styles.DownloadPageArea}>
            <h1>倭姫命の御朱印巡り</h1>
            <InstallButton isInstallable={isInstallable} setInstallable={setInstallable} />
            {!isInstallable && (
                <div className={styles.installedTextArea}>
                    <p>このブラウザを閉じてホーム画面に追加されたアプリを利用ください</p>
                </div>
            )}
        </div>
    )
}
