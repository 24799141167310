import ReactDOM from "react-dom/client"
import "./index.css"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import { ApplicationContextProvider } from "./contexts/ApplicationContextProvider"
import { AlbumContextProvider } from "./contexts/AlbumContextProvider"

ReactDOM.createRoot(document.getElementById("root")!).render(
    <BrowserRouter>
        <ApplicationContextProvider>
            <AlbumContextProvider>
                <App />
            </AlbumContextProvider>
        </ApplicationContextProvider>
    </BrowserRouter>
)
