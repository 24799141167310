import { Jinja } from "@/repositories/jinjaRepository"
import { useEffect, useState } from "react"

interface JinjaNameMarkerProps extends google.maps.MarkerOptions {
    jinja: Jinja
}

export const JinjaNameMarker: React.FC<JinjaNameMarkerProps> = (props: JinjaNameMarkerProps) => {
    const [marker, setMarker] = useState<google.maps.marker.AdvancedMarkerElement>(
        new google.maps.marker.AdvancedMarkerElement()
    )

    //markerが存在しなかったら作る、存在しててもアンマウントされたらmarkerを消す
    useEffect(() => {
        if (!marker) {
            setMarker(new google.maps.marker.AdvancedMarkerElement())
        }
        return () => {
            if (marker) {
                marker.map = null
            }
        }
    }, [marker])

    //markerのdesignの設定
    useEffect(() => {
        if (marker) {
            marker.position = props.position
            marker.map = props.map as google.maps.Map
            const label = document.createElement("p")
            label.style.fontFeatureSettings = "'pwid' on"
            label.style.fontFamily = "Noto Sans JP"
            label.style.color = "#4D4D4D"
            label.style.fontSize = "14px"
            label.style.fontStyle = "normal"
            label.style.fontWeight = "400"
            label.style.lineHeight = "125%"
            label.style.letterSpacing = "1.5px"
            label.style.webkitTextStrokeWidth = "0.5px"
            label.style.margin = "0"
            label.style.position = "absolute"
            label.style.top = "-29px"
            label.style.left = "18px"
            label.style.textShadow =
                "1px 1px 0 #FFF, -1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px -1px 0 #FFF, 0px 1px 0 #FFF, 0 -1px 0 #FFF, -1px 0 0 #FFF, 1px 0 0 #FFF"
            label.style.whiteSpace = "nowrap"
            label.innerText = props.jinja.name
            marker.content = label
            marker.collisionBehavior =
                google.maps.CollisionBehavior.OPTIONAL_AND_HIDES_LOWER_PRIORITY
            marker.zIndex = 50 - Number(props.jinja.jinjaId.slice(-2))
        }
    }, [marker, props])
    return null
}
