import { ResponseGetPicture } from "@/repositories/awsRepository"
import styles from "../pages/AlbumPage.module.scss"
import selectIcon from "@/assets/selectIcon.svg"
import publishedIcon from "@/assets/albumPage/publishedIcon.svg"
import notSelectIcon from "@/assets/notSelectIcon.svg"
import React, { useContext } from "react"
import { ApplicationContext } from "../contexts/ApplicationContextProvider"
import { convertToPicturesSortedByDate } from "@/functions/convertToPicturesSortedByDate"
import { convertToPicturesSortedByJinja } from "@/functions/convertToPicturesSortedByJinja"
import { AlbumContext, UserPicture } from "../contexts/AlbumContextProvider"

type Props = {
    picture: UserPicture
    index: number
    sortType: "day" | "jinja"
}
export const PictureCard = (props: Props) => {
    const {
        isSelectMode,
        numberOfSelectedPictures,
        setNumberOfSelectedPictures,
        setSinglePhotoViewMode,
    } = useContext(ApplicationContext)!

    const { userPictures, setUserPictures, setSelectPictureIndex } = useContext(AlbumContext)!

    return (
        <div
            key={`${props.sortType}-${props.index}`}
            onClick={() => {
                if (isSelectMode) {
                    if (props.picture.isSelect) {
                        setUserPictures((prevPictures) =>
                            prevPictures.map((prevPicture) => {
                                if (props.picture.pictureId === prevPicture.pictureId) {
                                    prevPicture.isSelect = false
                                    setNumberOfSelectedPictures(numberOfSelectedPictures - 1)
                                }
                                return prevPicture
                            })
                        )
                    } else {
                        setUserPictures((prevPictures) =>
                            prevPictures.map((prevPicture) => {
                                if (props.picture.pictureId === prevPicture.pictureId) {
                                    prevPicture.isSelect = true
                                    setNumberOfSelectedPictures(numberOfSelectedPictures + 1)
                                }
                                return prevPicture
                            })
                        )
                    }
                } else {
                    let sortedPictures: ResponseGetPicture[][]
                    if (props.sortType === "day") {
                        sortedPictures = convertToPicturesSortedByDate(userPictures)
                    } else {
                        sortedPictures = convertToPicturesSortedByJinja(userPictures)
                    }
                    const index = sortedPictures
                        .flat()
                        .findIndex(
                            (selectedPicture: ResponseGetPicture): boolean =>
                                selectedPicture.pictureId === props.picture.pictureId
                        )
                    setSelectPictureIndex(index)
                    setSinglePhotoViewMode("album")
                }
            }}
        >
            <img
                src={props.picture.getUrl}
                alt={props.picture.pictureId}
                className={props.picture.isSelect ? styles.photoSelected : styles.photo}
            />
            {props.picture.publishFlag && <div className={styles.publishedBackground}></div>}
            {isSelectMode &&
                (props.picture.isSelect ? (
                    <img src={selectIcon} alt="selectIcon" className={styles.selectIcon} />
                ) : (
                    <img src={notSelectIcon} alt="notSelectIcon" className={styles.selectIcon} />
                ))}
            {props.picture.publishFlag && (
                <img className={styles.publishedIcon} src={publishedIcon} alt="publishedIcon" />
            )}
        </div>
    )
}
