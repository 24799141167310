import { ApplicationContext } from "../contexts/ApplicationContextProvider"
import styles from "./Calendar.module.scss"
import { useContext } from "react"

type Props = {
    month: number
    now: Date
    selectedDate: number
}

export const Calendar = (props: Props) => {
    const { jinjaList, setSelectedDates } = useContext(ApplicationContext)!
    const isToday = (date: number) => {
        return (
            props.now.getDate() === date &&
            props.now.getMonth() === props.month &&
            props.now.getFullYear() === 2024
        )
    }

    const isSelectedDateOfMonth = (date: number) => {
        return date === props.selectedDate
    }

    const isValidDate = (date: number) => {
        return date > 0 && date <= new Date(2024, props.month + 1, 0).getDate()
    }

    const isEventDay = (date: number) => {
        return jinjaList.some((jinja) => {
            return jinja.eventList.some((event) => {
                return event.month === props.month + 1 && event.date === date
            })
        })
    }

    const MonthData = () => {
        let startDayIndex = new Date(2024, props.month, 1).getDay()
        if (startDayIndex == 0) startDayIndex = 7
        let dayCount = 1 - startDayIndex

        return new Array(6)
            .fill(null)
            .map(() =>
                new Array(7).fill(null).map(() => {
                    dayCount++
                    return dayCount
                })
            )
            .map((week, index) => {
                if (isValidDate(week[0]) || isValidDate(week[6]))
                    return (
                        <tr key={`week-${index}`}>
                            {week.map((date, index) => {
                                return (
                                    <td
                                        key={`date-${index}`}
                                        className={
                                            isToday(date)
                                                ? styles.today
                                                : isSelectedDateOfMonth(date)
                                                  ? styles.selectedDateOfMonth
                                                  : styles.notSelectedDate
                                        }
                                        onClick={() => {
                                            if (isValidDate(date)) {
                                                setSelectedDates((prevState: number[]) => {
                                                    const newState = [...prevState]
                                                    newState[props.month] = date
                                                    return newState
                                                })
                                            }
                                        }}
                                    >
                                        <div
                                            className={
                                                isEventDay(date)
                                                    ? styles.eventDate
                                                    : styles.noEventDate
                                            }
                                        >
                                            {isValidDate(date) && date}
                                        </div>
                                    </td>
                                )
                            })}
                        </tr>
                    )
            })
    }

    return (
        <div className={styles.calenderContents}>
            <table>
                <thead>
                    <tr>
                        <th>月</th>
                        <th>火</th>
                        <th>水</th>
                        <th>木</th>
                        <th>金</th>
                        <th>土</th>
                        <th>日</th>
                    </tr>
                </thead>
                <tbody data-testid={`calendar-${props.month}`}>
                    <MonthData />
                </tbody>
            </table>
        </div>
    )
}
