import styles from "./BackIcon.module.scss"

export const BackIcon = () => {
    return (
        <div className={styles.backIcon}>
            <div></div>
            <div></div>
        </div>
    )
}
