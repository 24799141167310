import Swal from "sweetalert2"
import styles from "./swalFires.module.scss"
import miyachi0Badge from "@/assets/myPage/miyachiBadges/0.png"
import miyachi1adge from "@/assets/myPage/miyachiBadges/1.png"
import miyachi2Badge from "@/assets/myPage/miyachiBadges/2.png"
import miyachi3Badge from "@/assets/myPage/miyachiBadges/3.png"
import miyachi4Badge from "@/assets/myPage/miyachiBadges/4.png"
import miyachi5Badge from "@/assets/myPage/miyachiBadges/5.png"
import miyachi6Badge from "@/assets/myPage/miyachiBadges/6.png"
import miyachi7Badge from "@/assets/myPage/miyachiBadges/7.png"
import miyachi8Badge from "@/assets/myPage/miyachiBadges/8.png"
import miyachi9Badge from "@/assets/myPage/miyachiBadges/9.png"
import miyachi10Badge from "@/assets/myPage/miyachiBadges/10.png"
import miyachi11Badge from "@/assets/myPage/miyachiBadges/11.png"
import miyachi12Badge from "@/assets/myPage/miyachiBadges/12.png"
import miyachi13Badge from "@/assets/myPage/miyachiBadges/13.png"
import miyachi14Badge from "@/assets/myPage/miyachiBadges/14.png"
import miyachi15Badge from "@/assets/myPage/miyachiBadges/15.png"
import miyachi16Badge from "@/assets/myPage/miyachiBadges/16.png"
import miyachi17Badge from "@/assets/myPage/miyachiBadges/17.png"
import miyachi18Badge from "@/assets/myPage/miyachiBadges/18.png"

export const miyachiBadgeObjArray = [
    { name: "倭姫宮", alt: "倭姫宮.png", src: miyachi0Badge, maximumCount: 1 },
    { name: "御室嶺上宮", alt: "御室嶺上宮.png", src: miyachi1adge, maximumCount: 3 },
    { name: "宇多秋宮", alt: "宇多秋宮.png", src: miyachi2Badge, maximumCount: 2 },
    { name: "佐々波多宮", alt: "佐々波多宮.png", src: miyachi3Badge, maximumCount: 3 },
    { name: "隠市守宮", alt: "隠市守宮.png", src: miyachi4Badge, maximumCount: 3 },
    { name: "穴穂宮", alt: "穴穂宮.png", src: miyachi5Badge, maximumCount: 2 },
    { name: "敢都美恵宮", alt: "敢都美恵宮.png", src: miyachi6Badge, maximumCount: 2 },
    { name: "甲可日雲宮", alt: "甲可日雲宮.png", src: miyachi7Badge, maximumCount: 2 },
    { name: "坂田宮", alt: "坂田宮.png", src: miyachi8Badge, maximumCount: 1 },
    { name: "伊久良河宮", alt: "伊久良河宮.png", src: miyachi9Badge, maximumCount: 3 },
    { name: "中嶋宮", alt: "中嶋宮.png", src: miyachi10Badge, maximumCount: 3 },
    { name: "桑名野代宮", alt: "桑名野代宮.png", src: miyachi11Badge, maximumCount: 3 },
    { name: "奈具波志忍山宮", alt: "奈具波志忍山宮.png", src: miyachi12Badge, maximumCount: 2 },
    { name: "藤方片樋宮", alt: "藤方片樋宮.png", src: miyachi13Badge, maximumCount: 3 },
    { name: "飯野高宮", alt: "飯野高宮.png", src: miyachi14Badge, maximumCount: 2 },
    { name: "佐々牟江宮", alt: "佐々牟江宮.png", src: miyachi15Badge, maximumCount: 2 },
    { name: "伊蘇宮", alt: "伊蘇宮.png", src: miyachi16Badge, maximumCount: 2 },
    { name: "瀧原宮", alt: "瀧原宮.png", src: miyachi17Badge, maximumCount: 1 },
    { name: "五十鈴宮", alt: "五十鈴宮.png", src: miyachi18Badge, maximumCount: 1 },
]

export const miyachiBadgeAcquisitionAnimation = (numberOfEachJinjaVisited: number[]) => {
    const isPortrait = () => {
        return window.innerHeight > window.innerWidth
    }
    const BadgeAwardedArray: number[] = []
    const acquiredBadgeListString = localStorage.getItem("acquiredMiyachiBadgeList")
    const acquiredBadgeList = acquiredBadgeListString ? JSON.parse(acquiredBadgeListString) : []
    const filteredMiyachiBadgeObjArray = miyachiBadgeObjArray.filter((miyachiBadgeObj, index) => {
        if (acquiredBadgeList.includes(miyachiBadgeObj.name)) {
            return false
        }
        BadgeAwardedArray.push(numberOfEachJinjaVisited[index])
        return true
    })

    const findIndex = filteredMiyachiBadgeObjArray.findIndex((miyachiBadgeObj, index) => {
        return BadgeAwardedArray[index] >= miyachiBadgeObj.maximumCount
    })
    if (findIndex !== -1) {
        acquiredBadgeList.push(filteredMiyachiBadgeObjArray[findIndex].name)
        localStorage.setItem("acquiredMiyachiBadgeList", JSON.stringify(acquiredBadgeList))
        Swal.fire({
            html:
                `<div class=${isPortrait() ? styles.imgAreaVerticalVersion : styles.imgAreaHorizontalVersion}>` +
                `<img alt=${filteredMiyachiBadgeObjArray[findIndex].alt} src=${filteredMiyachiBadgeObjArray[findIndex].src} />` +
                `</div>` +
                `<div>` +
                `<h3>バッジ獲得！</h3>` +
                `<h3>${filteredMiyachiBadgeObjArray[findIndex].name}参拝</h3>` +
                `</div>` +
                `<div>` +
                `<p>これまで獲得したバッジは</p>` +
                `<p>「マイページ」から確認できます</p>` +
                `</div>`,
            showConfirmButton: false,
            animation: false,
            customClass: {
                container: styles.badgeAcquisitionAnimationContainer,
                popup: styles.badgeAcquisitionAnimationPopup,
                htmlContainer: styles.htmlContainer,
            },
            didOpen: () => {
                const swal2Container = document.getElementsByClassName(
                    "swal2-container"
                )[0] as HTMLDivElement
                swal2Container.onclick = () => {
                    Swal.close()
                }
            },
        })
    }
}
