import React, { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import appLogoImage from "@/assets/appLogoImage.svg"
import rightArrowIcon from "@/assets/rightArrowIcon.png"
import { withToastPopup } from "../enhance/withToastPopup"
import { historyDateFormat } from "@/functions/createDate"
import { HistoryOfVisitGroupByDate, historyOfVisitsSort } from "@/services/historyOfVisitsSort"
import styles from "./HistoryOfVisits.module.scss"
import { WithToastPopupProps } from "../types/withToastPopupProps"
import { getPreSignedUrlObjectForPutUserPicture } from "@/repositories/awsRepository"
import { axiosPut } from "../clients/httpClient"
import { uploadUserPicture } from "@/repositories/userRepository"
import { ApplicationContext } from "../contexts/ApplicationContextProvider"
import { UserPicture } from "../contexts/AlbumContextProvider"
import imageCompression from "browser-image-compression"

export const HistoryOfVisits = (props: WithToastPopupProps) => {
    const [histories, setHistories] = useState<HistoryOfVisitGroupByDate[]>([])
    const { tourList } = useContext(ApplicationContext)!
    const navigate = useNavigate()
    const location = useLocation()

    const isAlbumPage = () => {
        return location.pathname === "/app/album"
    }

    useEffect(() => {
        historyOfVisitsSort().then((res) => {
            setHistories(res)
        })
    }, [])

    const onClickJinjaDetail = async (
        e: React.ChangeEvent<HTMLInputElement>,
        historyOfVisitId: string,
        jinjaId: string,
        jinjaName: string,
        jinjaLastWord: string,
        visitDate: number
    ) => {
        props.setIsShowPopup(false)
        for (const file of Array.from(e.target.files!)) {
            props.setIsLoading && props.setIsLoading(true)
            const preSignObjForPutPicture = await getPreSignedUrlObjectForPutUserPicture(file.type)
            const options = {
                maxSizeMB: 0.1,
                useWebWorker: true,
                maxWidthOrHeight: 720,
            }
            const singlePhotoOptions = {
                maxSizeMB: 0.5,
                useWebWorker: true,
                maxWidthOrHeight: 960,
            }
            const compressedFile = await imageCompression(file, options)
            const compressionSinglePhotoFile = await imageCompression(file, singlePhotoOptions)
            await axiosPut(preSignObjForPutPicture.putUrl, compressedFile)
            await axiosPut(preSignObjForPutPicture.originalPutUrl, file)
            await axiosPut(preSignObjForPutPicture.singlePhotoPutUrl, compressionSinglePhotoFile)

            const newPictureId = await uploadUserPicture(
                preSignObjForPutPicture.fileName,
                historyOfVisitId
            )
            props.setIsLoading && props.setIsLoading(false)
            const newUserPicture: UserPicture = {
                pictureId: newPictureId,
                registrationDate: preSignObjForPutPicture.registrationDate,
                jinjaId: jinjaId,
                jinjaName: jinjaName,
                jinjaLastWord: jinjaLastWord,
                historyOfVisitId: historyOfVisitId,
                getUrl: preSignObjForPutPicture.getUrl,
                singlePhotoGetUrl: preSignObjForPutPicture.singlePhotoGetUrl,
                fileName: preSignObjForPutPicture.fileName,
                visitDate: visitDate,
                publishFlag: false,
                userName: "",
                userIcon: "",
                isSelect: false,
            }
            props.setUserPictures!((prevState) => [newUserPicture, ...prevState])
        }
    }

    return (
        <div>
            {histories.length === 0 ? (
                <div className={styles.inviteMessageArea}>
                    <div className={styles.inviteMessageContainer}>
                        <img alt="appLogoImage" src={appLogoImage} />
                        <p>神社に参拝しませんか？</p>
                        <button
                            onClick={() => {
                                navigate("/app/home")
                                props.setIsShowPopup(false)
                            }}
                        >
                            HOME
                        </button>
                    </div>
                </div>
            ) : (
                <>
                    {histories.map((history, index) => {
                        return (
                            <div className={styles.visitsCardArea} key={index}>
                                <div className={styles.visitDateArea}>
                                    <p>{new Date(history.visitDate).getDate()}</p>
                                    <p>{historyDateFormat(history.visitDate)}</p>
                                </div>
                                <div className={styles.jinjaArea}>
                                    <div className={styles.line}></div>
                                    <div className={styles.jinjaDetailArea}>
                                        {history.jinjaList.map((jinja, index) => (
                                            <div
                                                className={styles.jinjaDetail}
                                                key={index}
                                                onClick={() => {
                                                    if (!isAlbumPage()) {
                                                        tourList.forEach((tour) => {
                                                            tour.jinjaList.forEach(
                                                                (jinjaInTour) => {
                                                                    if (
                                                                        jinjaInTour.jinjaId ===
                                                                        jinja.jinjaId
                                                                    ) {
                                                                        navigate(
                                                                            `/app/jinja-detail/${jinja.jinjaId}`,
                                                                            {
                                                                                state: {
                                                                                    orderNumber:
                                                                                        tour.orderNumber,
                                                                                    tourName:
                                                                                        tour.tourName,
                                                                                    tourNameKana:
                                                                                        tour.tourNameKana,
                                                                                },
                                                                            }
                                                                        )
                                                                    }
                                                                }
                                                            )
                                                        })
                                                    }
                                                }}
                                            >
                                                {isAlbumPage() && (
                                                    <input
                                                        type="file"
                                                        id="fileInput"
                                                        multiple
                                                        accept="image/png, image/jpeg"
                                                        onChange={(e) =>
                                                            onClickJinjaDetail(
                                                                e,
                                                                jinja.historyOfVisitId,
                                                                jinja.jinjaId,
                                                                jinja.jinjaName,
                                                                jinja.jinjaLastWord,
                                                                history.visitDate
                                                            )
                                                        }
                                                    />
                                                )}
                                                <div className={styles.visitCardArea}>
                                                    <img
                                                        alt="placeImage"
                                                        className={styles.placeImage}
                                                        src={`/jinjaImage/${jinja.jinjaId}.jpg`}
                                                        onError={(e) => {
                                                            const target =
                                                                e.target as HTMLImageElement
                                                            target.src =
                                                                "/jinjaImage/999default.jpg"
                                                        }}
                                                    />
                                                    <h2>{jinja.jinjaName + jinja.jinjaLastWord}</h2>
                                                    {!isAlbumPage() && (
                                                        <img
                                                            alt="rightArrowIcon"
                                                            src={rightArrowIcon}
                                                            className={styles.rightArrowIcon}
                                                        />
                                                    )}
                                                </div>
                                                {history.jinjaList.length !== index + 1 && (
                                                    <hr className={styles.separatorLine} />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </>
            )}
        </div>
    )
}

export const HistoryOfVisitWithToastPopup = withToastPopup<WithToastPopupProps>(HistoryOfVisits)
