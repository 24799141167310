import { Fragment, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import checkIn from "@/assets/checkInIcon.svg"
import filterIcon from "@/assets/generalIcons/filterIcon.svg"
import informationIcon from "@/assets/homePage/informationIcon.svg"
import favoriteOffIcon from "@/assets/homePage/favoriteOffIcon.svg"
import favoriteOnIcon from "@/assets/homePage/favoriteOnIcon.svg"
import detailArrowIcon from "@/assets/homePage/detailArrowIcon.svg"
import { ApplicationContext } from "../contexts/ApplicationContextProvider"
import { getHistoryOfVisits } from "@/repositories/historyOfVisitRepository"
import styles from "./HomePage.module.scss"
import {
    getUserFavoriteJinja,
    getUserIcon,
    getUserStatus,
    updateUserFavoriteJinja,
    UserIcon,
} from "@/repositories/userRepository"
import { SummaryPopup } from "@/components/SummaryPopup"
import { KoremadenoMeguri } from "@/components/KoremadenoMeguri"
import { isIphone } from "@/functions/isIphone"
import { getPreSignedUrlObjectForGetUserIcon } from "@/repositories/awsRepository"
import { CloseIcon } from "@/components/icon/CloseIcon"
import { cloneDeep } from "lodash"

type StateArray = {
    state: boolean | undefined
    text: string
    className?: undefined | string
}

type FilterState = {
    filterTitle: string
    filterName: string
    stateArray: StateArray[]
}

export const HomePage = () => {
    const {
        setUserIcon,
        setHistories,
        histories,
        tourList,
        areaHeight,
        toursAreaRef,
        favoriteList,
        setFavoriteList,
        stateOfJinjaFilter,
        setStateOfJinjaFilter,
    } = useContext(ApplicationContext)!

    const navigate = useNavigate()
    const [isShowSummaryPopup, setIsShowSummaryPopup] = useState(false)
    const [isShowMap, setIsShowMap] = useState(false)
    const [isShowJinjaFilter, setIsShowJinjaFilter] = useState(false)
    const [isClickable, setIsClickable] = useState(true)

    useEffect(() => {
        getHistoryOfVisits().then((res) => setHistories(res))
        getUserStatus().then((res) => {
            setIsShowSummaryPopup(!res.isCompleteSummary)
        })
        getUserIcon().then((it: UserIcon) => {
            if (it.isUserIcon) {
                getPreSignedUrlObjectForGetUserIcon().then((it) => {
                    setUserIcon(it.getUrl)
                })
            }
        })
        getUserFavoriteJinja().then((res) => {
            setFavoriteList(res)
        })
    }, [])

    useEffect(() => {
        const scroll = sessionStorage.getItem("scrollPosition")
            ? Number(sessionStorage.getItem("scrollPosition"))
            : 0
        if (toursAreaRef.current) {
            toursAreaRef.current.scrollTop = scroll
        }
    }, [tourList])

    const includeCheckIn = (name: string) => {
        const checkinNameList: string[] = histories.map((res) => res.jinjaName)
        return checkinNameList.includes(name)
    }

    const createJinjaFilterString = () => {
        let jinjaFilterString = ""
        stateOfJinjaFilter.forEach((state, index) => {
            if (index === 0) {
                if (state === true) jinjaFilterString = "お気に入り登録済み"
            }
            if (index === 1) {
                if (jinjaFilterString.length > 0 && state !== undefined) jinjaFilterString += ","
                if (state === true) jinjaFilterString += "参拝履歴有り"
                if (state === false) jinjaFilterString += "参拝履歴無し"
            }
            if (index === 2) {
                if (jinjaFilterString.length > 0 && state !== undefined) jinjaFilterString += ","
                if (state === true) jinjaFilterString += "御朱印有り"
                if (state === false) jinjaFilterString += "御朱印無し"
            }
            if (index === 3) {
                if (jinjaFilterString.length > 0 && state !== undefined) jinjaFilterString += ","
                if (state === true) jinjaFilterString += "お手洗い有り"
                if (state === false) jinjaFilterString += "お手洗い無し"
            }
            if (index === 4) {
                if (jinjaFilterString.length > 0 && state !== undefined) jinjaFilterString += ","
                if (state === true) jinjaFilterString += "無料駐車場有り"
                if (state === false) jinjaFilterString += "無料駐車場無し"
            }
        })
        if (jinjaFilterString.length === 0) return "絞り込み"
        return jinjaFilterString
    }

    const filterRadioButtonClick = (radioButtonIndex: number, state: boolean | undefined) => {
        setStateOfJinjaFilter((prevState) => {
            const newState = cloneDeep(prevState)
            newState[radioButtonIndex] = state
            return newState
        })
    }

    const isFavorite = (jinjaId: string) => {
        if (favoriteList.length === 0) return false
        return favoriteList.includes(jinjaId)
    }

    const filteredTourListFunc = () => {
        const newTourList = cloneDeep(tourList)
        tourList.forEach((tour, index) => {
            newTourList[index].jinjaList = tour.jinjaList.filter((jinja) => {
                if (stateOfJinjaFilter[0] === true && !favoriteList.includes(jinja.jinjaId))
                    return false
                if (stateOfJinjaFilter[1] === true) {
                    const jinjaIds = histories.map((history) => history.jinjaId)
                    if (!jinjaIds.includes(jinja.jinjaId)) return false
                } else if (stateOfJinjaFilter[1] === false) {
                    const jinjaIds = histories.map((history) => history.jinjaId)
                    if (jinjaIds.includes(jinja.jinjaId)) return false
                }
                if (stateOfJinjaFilter[2] === true && jinja.jinjaInfo.receptionInfo === "無し")
                    return false
                if (stateOfJinjaFilter[2] === false && !(jinja.jinjaInfo.receptionInfo === "無し"))
                    return false
                if (stateOfJinjaFilter[3] === true && !(jinja.jinjaInfo.restRoom === "有り"))
                    return false
                if (stateOfJinjaFilter[3] === false && !(jinja.jinjaInfo.restRoom === "無し"))
                    return false
                if (stateOfJinjaFilter[4] === true && jinja.jinjaInfo.parking.includes("無し"))
                    return false
                return !(
                    stateOfJinjaFilter[4] === false && !jinja.jinjaInfo.parking.includes("無し")
                )
            })
        })
        return newTourList.filter((tour) => {
            return tour.jinjaList.length !== 0
        })
    }

    const filteredTourList = filteredTourListFunc()

    const createNothingMessage = () => {
        if (stateOfJinjaFilter.includes(true) || stateOfJinjaFilter.includes(false))
            return <p className={styles.nothingMessage}>該当する神社はありません</p>
    }
    const filterFavorite: FilterState = {
        filterTitle: "お気に入り",
        filterName: "favoriteGroup",
        stateArray: [
            { state: undefined, text: "設定なし" },
            { state: true, text: "登録済み", className: styles.twoColumn },
        ],
    }
    const filterHistoryOfVisit: FilterState = {
        filterTitle: "参拝履歴",
        filterName: "historyOfVisitGroup",
        stateArray: [
            { state: undefined, text: "設定なし" },
            { state: true, text: "有り" },
            { state: false, text: "無し" },
        ],
    }
    const filterRedSeawlGroup: FilterState = {
        filterTitle: "御朱印",
        filterName: "redSealGroup",
        stateArray: [
            { state: undefined, text: "設定なし" },
            { state: true, text: "有り" },
            { state: false, text: "無し" },
        ],
    }
    const filterToilet: FilterState = {
        filterTitle: "お手洗い",
        filterName: "toiletGroup",
        stateArray: [
            { state: undefined, text: "設定なし" },
            { state: true, text: "有り" },
            { state: false, text: "無し" },
        ],
    }
    const filterParkingGroup: FilterState = {
        filterTitle: "無料駐車場",
        filterName: "parkingGroup",
        stateArray: [
            { state: undefined, text: "設定なし" },
            { state: true, text: "有り" },
            { state: false, text: "無し" },
        ],
    }
    return (
        <div
            className={`${styles.homePageArea}`}
            style={isIphone() ? { height: `${areaHeight}px` } : undefined}
        >
            <div className={styles.headerArea}>
                <div className={styles.headerTopArea}>
                    <h1>
                        <button
                            onClick={() => {
                                setIsShowSummaryPopup(true)
                            }}
                        >
                            <ruby data-ruby="やまとひめのみこと">倭姫命の御巡行路</ruby>
                            <img src={informationIcon} alt="informationIcon" />
                            <hr />
                        </button>
                    </h1>
                    <button
                        onClick={() => {
                            navigate("/app/map")
                        }}
                    >
                        <img alt="detailArrowIcon" src={detailArrowIcon} />
                        経路作成
                    </button>
                </div>
                <div
                    className={styles.jinjaFilterPlaceholder}
                    onClick={() => {
                        setIsShowJinjaFilter(true)
                    }}
                >
                    <img src={filterIcon} alt="filterIcon" />
                    <p>{createJinjaFilterString()}</p>
                </div>
                {isShowJinjaFilter && (
                    <div className={styles.jinjaFilterArea}>
                        <button
                            onClick={() => {
                                setIsShowJinjaFilter(false)
                            }}
                        >
                            <CloseIcon />
                        </button>
                        <div className={styles.jinjaFilterGrid}>
                            {[
                                filterFavorite,
                                filterHistoryOfVisit,
                                filterRedSeawlGroup,
                                filterToilet,
                                filterParkingGroup,
                            ].map((element, index) => {
                                return (
                                    <Fragment key={index}>
                                        <p>{element.filterTitle}</p>
                                        {element.stateArray.map((stateElement, stateIndex) => {
                                            return (
                                                <label
                                                    className={stateElement.className}
                                                    key={stateIndex}
                                                >
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            name={element.filterName}
                                                            defaultChecked={
                                                                stateOfJinjaFilter[index] ===
                                                                stateElement.state
                                                            }
                                                            onClick={() => {
                                                                filterRadioButtonClick(
                                                                    index,
                                                                    stateElement.state
                                                                )
                                                            }}
                                                        />
                                                        <span className={styles.customRadio} />
                                                    </div>
                                                    {stateElement.text}
                                                </label>
                                            )
                                        })}
                                    </Fragment>
                                )
                            })}
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.toursArea} ref={toursAreaRef}>
                {filteredTourList.length > 0
                    ? filteredTourList.map((tour, index) => {
                          return (
                              <div key={index} className={styles.tourArea}>
                                  <h2>
                                      {tour.orderNumber}.
                                      <ruby data-ruby={tour.tourNameKana}>{tour.tourName}</ruby>
                                  </h2>

                                  <div className={styles.jinjaArea}>
                                      {tour.jinjaList.map((jinja, index) => {
                                          const isChecked = includeCheckIn(jinja.name)
                                          return (
                                              <div
                                                  key={index}
                                                  className={`${styles.jinja} ${isChecked ? styles.checkInJinja : ""}`}
                                                  onClick={() => {
                                                      sessionStorage.setItem(
                                                          "scrollPosition",
                                                          toursAreaRef.current!.scrollTop.toString()
                                                      )
                                                      navigate(
                                                          `/app/jinja-detail/${jinja.jinjaId}`,
                                                          {
                                                              state: {
                                                                  orderNumber: tour.orderNumber,
                                                                  tourName: tour.tourName,
                                                                  tourNameKana: tour.tourNameKana,
                                                              },
                                                          }
                                                      )
                                                  }}
                                              >
                                                  <img
                                                      alt={jinja.name}
                                                      src={`/jinjaImage/${jinja.jinjaId}.jpg`}
                                                      onError={(e) => {
                                                          const target =
                                                              e.target as HTMLImageElement
                                                          target.alt = "defaultImg"
                                                          target.src = "/jinjaImage/999default.jpg"
                                                      }}
                                                  />
                                                  {isFavorite(jinja.jinjaId) ? (
                                                      <img
                                                          alt={`favoriteOn${jinja.name}`}
                                                          src={favoriteOnIcon}
                                                          className={styles.favorite}
                                                          onClick={(e) => {
                                                              e.stopPropagation()
                                                              if (isClickable) {
                                                                  setIsClickable(false)
                                                                  updateUserFavoriteJinja(
                                                                      jinja.jinjaId
                                                                  )
                                                                      .then((res) => {
                                                                          setFavoriteList(res)
                                                                          setIsClickable(true)
                                                                      })
                                                                      .catch(() => {
                                                                          setIsClickable(true)
                                                                      })
                                                              }
                                                          }}
                                                      />
                                                  ) : (
                                                      <img
                                                          alt={`favoriteOff${jinja.name}`}
                                                          src={favoriteOffIcon}
                                                          className={styles.favorite}
                                                          onClick={(e) => {
                                                              e.stopPropagation()
                                                              if (isClickable) {
                                                                  setIsClickable(false)
                                                                  updateUserFavoriteJinja(
                                                                      jinja.jinjaId
                                                                  )
                                                                      .then((res) => {
                                                                          setFavoriteList(res)
                                                                          setIsClickable(true)
                                                                      })
                                                                      .catch(() => {
                                                                          setIsClickable(true)
                                                                      })
                                                              }
                                                          }}
                                                      />
                                                  )}
                                                  {jinja.redSealAlbumPublish && (
                                                      <p className={styles.redSeal}>御朱印帳掲載</p>
                                                  )}
                                                  <div>
                                                      <div>
                                                          {isChecked && (
                                                              <img
                                                                  className={styles.checkMark}
                                                                  src={checkIn}
                                                                  alt="checkIn"
                                                              />
                                                          )}
                                                      </div>
                                                      <p className={styles.jinjaName}>
                                                          {jinja.name}
                                                          <wbr />
                                                          {jinja.lastWord}
                                                      </p>
                                                  </div>
                                              </div>
                                          )
                                      })}
                                  </div>
                              </div>
                          )
                      })
                    : createNothingMessage()}
            </div>
            {isShowSummaryPopup && <SummaryPopup setIsPopupSummary={setIsShowSummaryPopup} />}
            {isShowMap && (
                <KoremadenoMeguri setIsShowMap={setIsShowMap} toursAreaRef={toursAreaRef} />
            )}
        </div>
    )
}
