import Swal from "sweetalert2"
import errorIcon from "@/assets/errorIcon.svg"
import styles from "./swalFires.module.scss"

export const selectedJinjaOfOverPopup = () => {
    Swal.fire({
        html:
            `<img alt="errorIcon" src=${errorIcon} />` +
            `<h3>これ以上選択できません</h3>` +
            `<p>選択できる神社は最大9箇所です</p>` +
            `<button class=${styles.closeButton} id="selectedJinjaOfOverPopup_closeButton">閉じる</button>`,
        showConfirmButton: false,
        allowOutsideClick: false,
        customClass: {
            popup: styles.selectedJinjaOfOverPopup,
            htmlContainer: styles.htmlContainer,
        },
        didOpen: () => {
            const closeButton = document.getElementById("selectedJinjaOfOverPopup_closeButton")
            closeButton!.onclick = () => {
                Swal.close()
            }
        },
    })
}
