import { withToastPopup } from "../enhance/withToastPopup"
import React, { useEffect, useState } from "react"
import loginBadge from "@/assets/myPage/loginBadge.png"
import homeBadge from "@/assets/myPage/homeBadge.png"
import cameraBadge from "@/assets/myPage/cameraBadge.png"
import _1stBadge from "@/assets/myPage/1stBadge.png"
import _3rdBadge from "@/assets/myPage/3rdBadge.png"
import _10thBadge from "@/assets/myPage/10thBadge.png"
import _20thBadge from "@/assets/myPage/20thBadge.png"
import _30thBadge from "@/assets/myPage/30thBadge.png"
import lockIcon from "@/assets/myPage/lockIcon.svg"
import completeBadge from "@/assets/myPage/completeBadge.png"
import { getUserBadgeInfo, UserBadgeInfo } from "@/repositories/userRepository"
import styles from "./BadgeCollection.module.scss"
import { badgeProgressStyle } from "@/functions/badgeProgressStyle"
import { miyachiBadgeObjArray } from "@/functions/swalPopups/miyachiBadgeAcquisitionAnimation"

export type BadgeCollectionViewProps = {
    setIsShowPopup: (compareStartWithEnd: boolean) => void
    isBadge?: boolean
}

export const BadgeCollectionView = () => {
    const [badgeStatus, setBadgeStatus] = useState<UserBadgeInfo>({
        isPostAlbum: false,
        isAddHome: false,
        numberOfVisitJinja: 0,
        numberOfEachJinjaVisited: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    })

    useEffect(() => {
        getUserBadgeInfo().then((res) => setBadgeStatus(res))
    }, [])

    const isGetBadge = (badgeFlag: boolean) => {
        if (badgeFlag) {
            return ""
        } else {
            return styles.noBadge
        }
    }

    const isGetVisitJinjaBadge = (criterion: number) => {
        if (badgeStatus.numberOfVisitJinja >= criterion) {
            return ""
        } else {
            return styles.noBadge
        }
    }

    const sanpaiBadgeObjArray = [
        { criterion: 1, name: "一社参拝", altName: "1stBadge", imgName: _1stBadge },
        { criterion: 3, name: "三社参拝", altName: "3rdBadge", imgName: _3rdBadge },
        { criterion: 10, name: "十社参拝", altName: "10thBadge", imgName: _10thBadge },
        { criterion: 20, name: "二十社参拝", altName: "20thBadge", imgName: _20thBadge },
        { criterion: 30, name: "三十社参拝", altName: "30thBadge", imgName: _30thBadge },
        {
            criterion: 41,
            name: "コンプリート",
            altName: "completeBadge",
            imgName: completeBadge,
        },
    ]

    return (
        <div className={styles.badgeCollectionArea}>
            <section>
                <h3>アクションバッジ</h3>
                <div>
                    <div>
                        <img alt="loginBadge" src={loginBadge} />
                        <p className={styles.twoLine}>初回ログイン</p>
                        <div className={styles.progressCircle} style={badgeProgressStyle(0, 0)} />
                    </div>
                    <div className={isGetBadge(badgeStatus?.isAddHome)}>
                        <img alt="homeBadge" src={homeBadge} />
                        <p className={styles.twoLine}>
                            ホーム画面に
                            <br />
                            アプリを追加
                        </p>
                        <div className={styles.progressCircle} style={badgeProgressStyle(0, 0)} />
                        {!badgeStatus?.isAddHome && (
                            <img className={styles.lockIcon} alt="lockIcon" src={lockIcon} />
                        )}
                    </div>
                    <div className={isGetBadge(badgeStatus?.isPostAlbum)}>
                        <img alt="cameraBadge" src={cameraBadge} />
                        <p className={styles.twoLine}>初回写真追加</p>
                        <div className={styles.progressCircle} style={badgeProgressStyle(0, 0)} />
                        {!badgeStatus?.isPostAlbum && (
                            <img className={styles.lockIcon} alt="lockIcon" src={lockIcon} />
                        )}
                    </div>
                </div>
            </section>
            <section>
                <h3>参拝バッジ</h3>
                <div>
                    {sanpaiBadgeObjArray.map(({ criterion, name, altName, imgName }, index) => {
                        return (
                            <div
                                key={`numberOfVisitsJinjaBadgeArea-${index}`}
                                className={isGetVisitJinjaBadge(criterion)}
                            >
                                <img alt={altName} src={imgName} />
                                <p>{name}</p>
                                <div
                                    className={styles.progressCircle}
                                    style={badgeProgressStyle(
                                        badgeStatus.numberOfVisitJinja,
                                        criterion
                                    )}
                                />
                                {badgeStatus.numberOfVisitJinja < criterion && (
                                    <img
                                        className={styles.lockIcon}
                                        alt="lockIcon"
                                        src={lockIcon}
                                    ></img>
                                )}
                            </div>
                        )
                    })}
                </div>
            </section>
            <section>
                <h3>宮地バッジ</h3>
                <div>
                    {badgeStatus.numberOfEachJinjaVisited.map((numberOfVisit, index) => {
                        return (
                            <div
                                key={`miyachiBadgesArea-${index}`}
                                className={
                                    numberOfVisit < miyachiBadgeObjArray[index].maximumCount
                                        ? styles.noBadge
                                        : ""
                                }
                            >
                                <img
                                    alt={`miyachi${index}Badge`}
                                    src={miyachiBadgeObjArray[index].src}
                                />
                                <p>{miyachiBadgeObjArray[index].name}</p>
                                <div
                                    className={styles.progressCircle}
                                    style={badgeProgressStyle(
                                        numberOfVisit,
                                        miyachiBadgeObjArray[index].maximumCount
                                    )}
                                />
                                {numberOfVisit < miyachiBadgeObjArray[index].maximumCount && (
                                    <img
                                        className={styles.lockIcon}
                                        alt="lockIcon"
                                        src={lockIcon}
                                    />
                                )}
                            </div>
                        )
                    })}
                </div>
            </section>
        </div>
    )
}

export const BadgeCollectionViewWithToastPopup =
    withToastPopup<BadgeCollectionViewProps>(BadgeCollectionView)
