import { useContext, useState } from "react"
import { ApplicationContext } from "../contexts/ApplicationContextProvider"
import { EventInfo } from "@/repositories/jinjaRepository"
import downArrowIcon from "@/assets/eventPage/downArrowIcon.svg"
import toriiIcon from "@/assets/eventPage/toriiIcon.svg"
import style from "../components/EventDetail.module.scss"
import { useNavigate } from "react-router-dom"
import { Tour } from "@/repositories/tourRepository"

type Props = {
    selectedDateByEachMonth: number[]
    month: number
}

type EventDetail = EventInfo & {
    tourInfo: Omit<Tour, "jinjaList">
    jinjaId: string
    jinjaName: string
    jinjaLastWord: string
}

export const EventDetail = (props: Props) => {
    const { tourList } = useContext(ApplicationContext)!
    const days = ["日", "月", "火", "水", "木", "金", "土"]
    const selectDay = new Date(2024, props.month, props.selectedDateByEachMonth[props.month])

    const generateEventList = () => {
        const todayEventList: EventDetail[] = []
        tourList.forEach((tour) => {
            tour.jinjaList.forEach((jinja) => {
                const filteredEventList = jinja.eventList.filter((event) => {
                    return (
                        event.month === props.month + 1 &&
                        event.date === props.selectedDateByEachMonth[props.month]
                    )
                })
                filteredEventList.forEach((filteredEvent) => {
                    todayEventList.push({
                        tourInfo: tour,
                        jinjaId: jinja.jinjaId,
                        jinjaName: jinja.name,
                        jinjaLastWord: jinja.lastWord,
                        ...filteredEvent,
                    })
                })
            })
        })
        if (todayEventList.length !== 0) {
            return (
                <ul className={style.list}>
                    {todayEventList.map((event, index) => (
                        <EventDetailContent
                            event={event}
                            tour={event.tourInfo}
                            index={index}
                            todayEventList={todayEventList}
                            key={`${props.month}-${props.selectedDateByEachMonth[props.month]}-${index}`}
                        />
                    ))}
                </ul>
            )
        } else {
            return <p className={style.noEventMessage}>イベントはありません</p>
        }
    }

    return (
        <>
            <p className={style.eventDate}>
                {selectDay.getMonth() + 1}月{selectDay.getDate()}日({days[selectDay.getDay()]})
            </p>
            {generateEventList()}
        </>
    )
}

type EventDetailContentProps = {
    event: EventDetail
    tour: Omit<Tour, "jinjaList">
    index: number
    todayEventList: EventDetail[]
}

const EventDetailContent = (props: EventDetailContentProps) => {
    const [isShowEventDetail, setIsShowEventDetail] = useState(false)
    const navigate = useNavigate()
    return (
        <li onClick={() => setIsShowEventDetail((prevState: boolean) => !prevState)}>
            <div className={style.eventTime}>
                <p>{props.event.time}</p>
            </div>
            <h3>{props.event.name}</h3>
            {isShowEventDetail && <p>{props.event.details}</p>}
            <div className={style.jinjaName}>
                <div
                    onClick={() => {
                        navigate(`/app/jinja-detail/${props.event.jinjaId}`, {
                            state: {
                                orderNumber: props.tour.orderNumber,
                                tourName: props.tour.tourName,
                                tourNameKana: props.tour.tourNameKana,
                            },
                        })
                    }}
                >
                    <img alt="toriiIcon" src={toriiIcon}></img>
                    <p>{`${props.event.jinjaName}${props.event.jinjaLastWord}`}</p>
                </div>
            </div>
            <img
                alt="downArrowIcon"
                src={downArrowIcon}
                className={isShowEventDetail ? style.upArrow : ""}
            />
            {props.todayEventList.length !== props.index + 1 && <hr />}
        </li>
    )
}
