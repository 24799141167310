export class SwipeCheckService {
    private touchStartY = 0
    private touchEndY = 0

    getProperty() {
        return {
            touchStartY: this.touchStartY,
            touchEndY: this.touchEndY,
        }
    }

    setTouchStartY(touchStartY: number) {
        this.touchStartY = touchStartY
        this.touchEndY = 0
    }
    setTouchEndY(touchEndY: number) {
        this.touchEndY = touchEndY
    }

    compareStartWithEnd(): boolean {
        return this.touchStartY < this.touchEndY
    }
}

export const swipeCheckService = new SwipeCheckService()
