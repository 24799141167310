import { csrfManager } from "../csrf/CsrfManager"
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "../clients/httpClient"
import { AxiosResponse } from "axios"

export type UserStatus = {
    isCompleteSummary: boolean
    oid: string
}

export type UserBadgeInfo = {
    isPostAlbum: boolean
    isAddHome: boolean
    numberOfVisitJinja: number
    numberOfEachJinjaVisited: number[]
}

export type UserNickName = {
    nickName: string | null
}

export type UserIcon = {
    isUserIcon: boolean
}

export type UserFavoriteJinja = {
    jinja: string
}

export const getUserStatus = async (): Promise<UserStatus> => {
    const { data } = (await axiosGet("/api/user/status")) as AxiosResponse
    return data
}

export const updateUserStatus = async () => {
    await axiosPut(
        "/api/user/status",
        { isCompleteSummary: true },
        {
            headers: {
                "X-CSRF-TOKEN": csrfManager.getCsrf(),
            },
        }
    )
}

export const uploadUserPicture = async (
    fileName: string,
    historyOfVisitId: string
): Promise<string> => {
    const { data } = (await axiosPost(
        "/api/user/upload-picture",
        { fileName: fileName, historyOfVisitId: historyOfVisitId },
        {
            headers: {
                "X-CSRF-TOKEN": csrfManager.getCsrf(),
            },
        }
    )) as AxiosResponse
    return data
}

export const publishUserPicture = async (pictureId: string, isPublish: boolean) => {
    await axiosPut(
        "/api/user/publish-picture",
        { pictureId: pictureId, isPublish: isPublish },
        {
            headers: {
                "X-CSRF-TOKEN": csrfManager.getCsrf(),
            },
            timeout: 5000,
        }
    )
}

export const deleteUserPicture = async (historyOfVisitId: string, fileName: string) => {
    await axiosDelete("/api/user/delete-picture", {
        params: { historyOfVisitId: historyOfVisitId, fileName: fileName },
        headers: {
            "X-CSRF-TOKEN": csrfManager.getCsrf(),
        },
    })
}

export const getNickName = async (): Promise<UserNickName> => {
    const { data } = (await axiosGet("/api/user/get-nick-name")) as AxiosResponse
    return data
}

export const updateNickName = async (nickName: string) => {
    await axiosPut(
        "/api/user/update-nick-name",
        {
            nickName: nickName,
        },
        {
            headers: {
                "X-CSRF-TOKEN": csrfManager.getCsrf(),
            },
        }
    )
}

export const getUserIcon = async (): Promise<UserIcon> => {
    const { data } = (await axiosGet("/api/user/get-user-icon")) as AxiosResponse
    return data
}

export const updateUserIcon = async (): Promise<void> => {
    await axiosPost(
        "/api/user/update-user-icon",
        {
            isUserIcon: true,
        },
        {
            headers: {
                "X-CSRF-TOKEN": csrfManager.getCsrf(),
            },
        }
    )
}

export const getUserBadgeInfo = async (): Promise<UserBadgeInfo> => {
    const { data } = (await axiosGet("/api/user/badge-info")) as AxiosResponse
    return data
}

export const updateAddHome = async (): Promise<void> => {
    await axiosPut(
        "/api/user/update-add-home",
        {
            isAddHome: true,
        },
        {
            headers: {
                "X-CSRF-TOKEN": csrfManager.getCsrf(),
            },
        }
    )
}

export const saveFcmToken = async (token: string | null): Promise<void> => {
    await axiosPost(
        "/api/user/token",
        {
            token: token,
        },
        {
            headers: {
                "X-CSRF-TOKEN": csrfManager.getCsrf(),
            },
            timeout: 5000,
        }
    )
}
export const getFcmToken = async (): Promise<string | null> => {
    const res: AxiosResponse<{ notificationToken: string | null }> =
        await axiosGet("/api/user/token")
    return res.data.notificationToken
}

export const updateLoginTime = async () => {
    await axiosGet("/api/user/update-login-time")
}

export const getUserFavoriteJinja = async (): Promise<string[]> => {
    const { data } = (await axiosGet("/api/user/favoriteJinja")) as AxiosResponse
    return data
}

export const updateUserFavoriteJinja = async (jinjaId: string): Promise<string[]> => {
    const { data } = (await axiosPut(
        "/api/user/favoriteJinja",
        {
            jinjaId: jinjaId,
        },
        {
            headers: {
                "X-CSRF-TOKEN": csrfManager.getCsrf(),
            },
            timeout: 5000,
        }
    )) as AxiosResponse
    return data
}
