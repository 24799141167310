import Swal from "sweetalert2"
import styles from "./swalFires.module.scss"
import kurukuruIcon from "@/assets/kurukuruIcon.svg"
import { getToken } from "firebase/messaging"
import { messaging } from "../../firebaseConfig"
import { saveFcmToken } from "@/repositories/userRepository"
import notificationOnIcon from "@/assets/myPage/notificationOnIcon.svg"
import { notificationErrorPopup } from "@/functions/swalPopups/notificationErrorPopup"

export const notificationReceivePopup = (
    toggleNotificationDivRef: React.RefObject<HTMLDivElement>
) => {
    Swal.fire({
        html:
            "<h3>通知設定をオンにして<br/>最新情報を受け取りますか？</h3>" +
            "<div>" +
            "<button id='notificationReceivePopup_cancelButton'>キャンセル</button>" +
            "<button id='notificationReceivePopup_confirmButton'>通知オン</button>" +
            "</div>",
        showConfirmButton: false,
        allowOutsideClick: false,
        customClass: {
            popup: styles.notificationReceivePopup,
            htmlContainer: styles.htmlContainer,
        },
        didOpen: () => {
            const cancelButton = document.getElementById("notificationReceivePopup_cancelButton")
            cancelButton!.onclick = () => {
                Swal.clickCancel()
            }
            const confirmButton = document.getElementById("notificationReceivePopup_confirmButton")
            confirmButton!.onclick = () => {
                Swal.clickConfirm()
            }
        },
    }).then((result) => {
        if (result.isConfirmed) {
            Swal.fire({
                html:
                    `<img alt='kurukuruIcon' src=${kurukuruIcon} />` +
                    "<h3>通知設定を変更中...</h3>",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                customClass: {
                    popup: styles.loadingPopup,
                    htmlContainer: styles.htmlContainer,
                },
            })

            getToken(messaging, { vapidKey: import.meta.env.VITE_FCM_PUBLIC_KEY })
                .then((token) => {
                    saveFcmToken(token)
                        .then(() => {
                            if (toggleNotificationDivRef.current) {
                                const img = toggleNotificationDivRef.current
                                    .children[0] as HTMLImageElement
                                img.alt = "notificationOnIcon"
                                img.src = notificationOnIcon
                                const p = toggleNotificationDivRef.current
                                    .children[1] as HTMLParagraphElement
                                p.innerHTML = "通知オン"
                            }
                            Swal.close()
                        })
                        .catch((error) => {
                            console.error(error)
                            notificationErrorPopup()
                        })
                })
                .catch((error) => {
                    console.error(error)
                    notificationErrorPopup()
                })
        }
    })
}
