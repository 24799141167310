import { convertToPicturesSortedByJinja } from "@/functions/convertToPicturesSortedByJinja"
import { convertToPicturesSortedByDate } from "@/functions/convertToPicturesSortedByDate"
import styles from "../pages/AlbumPage.module.scss"
import { PictureCard } from "./PictureCard"
import React, { useContext } from "react"
import { AlbumContext } from "../contexts/AlbumContextProvider"
import { historyDateFormat } from "@/functions/createDate"

export const SortPhotosArea = () => {
    const { userPictures, isDaySort } = useContext(AlbumContext)!
    const convertToSortedPictures = () => {
        return isDaySort
            ? convertToPicturesSortedByDate(userPictures)
            : convertToPicturesSortedByJinja(userPictures)
    }

    return (
        <div>
            {convertToSortedPictures().map((Pictures, index) => {
                return (
                    <div key={`pictures-${index}`}>
                        {isDaySort ? (
                            <div className={styles.albumDateArea}>
                                <p>{new Date(Pictures[0].visitDate).getDate()}</p>
                                <p>{historyDateFormat(Pictures[0].visitDate)}</p>
                            </div>
                        ) : (
                            <h3 className={styles.albumJinjaNameArea}>
                                {Pictures[0].jinjaName + Pictures[0].jinjaLastWord}
                            </h3>
                        )}
                        <div className={styles.albumPhotoArea}>
                            {Pictures.map((picture, index: number) => (
                                <PictureCard
                                    picture={picture}
                                    index={index}
                                    sortType={isDaySort ? "day" : "jinja"}
                                    key={index}
                                />
                            ))}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
