import Swal from "sweetalert2"
import errorIcon from "@/assets/errorIcon.svg"
import styles from "@/functions/swalPopups/swalFires.module.scss"

export const notNearbyJinjaPopup = () => {
    Swal.fire({
        html:
            `<img alt='errorIcon' src=${errorIcon} />` +
            "<h3>神社から離れています</h3>" +
            "<p>拝殿に近づいてください</p>" +
            `<button class=${styles.closeButton} id='notNearbyJinjaPopup_closeButton'>閉じる</button>`,
        showConfirmButton: false,
        allowOutsideClick: false,
        customClass: {
            popup: styles.notNearbyJinjaPopup,
            htmlContainer: styles.htmlContainer,
        },
        didOpen: () => {
            const closeButton = document.getElementById("notNearbyJinjaPopup_closeButton")
            closeButton!.onclick = () => {
                Swal.close()
            }
        },
    })
}
