import { createDateFormatJapanese } from "@/functions/createDate"
import { Jinja, Position } from "@/repositories/jinjaRepository"

export type GetNearJinjaResponse = {
    foundJinja: Jinja | undefined
    time: number
}

class CheckDistanceService {
    private ratioForLat = 111319
    private ratioForLng = 90429
    private startTime = 0
    private endTime = 0

    calculateDistance(currentPosition: Position, targetPosition: Position): number {
        const distanceLatMeter =
            (targetPosition.latitude - currentPosition.latitude) * this.ratioForLat
        const distanceLngMeter =
            (targetPosition.longitude - currentPosition.longitude) * this.ratioForLng

        return Math.floor(Math.sqrt(distanceLatMeter ** 2 + distanceLngMeter ** 2))
    }
    async getNearJinja(
        currentPosition: Position,
        jinjaList: Jinja[]
    ): Promise<GetNearJinjaResponse> {
        this.startTime = Date.now()
        const foundJinja = jinjaList.find((jinja) => {
            const result = this.calculateDistance(currentPosition, {
                latitude: jinja.position.latitude,
                longitude: jinja.position.longitude,
            })
            if (jinja.jinjaId === "00000000-0000-0000-0000-000000000018") {
                const limitDistanceOfMeter = 300
                if (result <= limitDistanceOfMeter) return true
            } else if (jinja.jinjaId === "00000000-0000-0000-0000-000000000017") {
                const limitDistanceOfMeter = 270
                if (result <= limitDistanceOfMeter) return true
            } else if (
                jinja.jinjaId === "00000000-0000-0000-0000-000000000000" ||
                jinja.jinjaId === "00000000-0000-0000-0000-000000000003" ||
                jinja.jinjaId === "00000000-0000-0000-0000-000000000014"
            ) {
                const limitDistanceOfMeter = 150
                if (result <= limitDistanceOfMeter) return true
            } else {
                const limitDistanceOfMeter = 100
                if (result <= limitDistanceOfMeter) return true
            }
        })
        this.endTime = Date.now()
        return {
            foundJinja: foundJinja,
            time: this.endTime - this.startTime,
        }
    }

    registerArrivalDate(jinjaName: string): void {
        localStorage.setItem(jinjaName, createDateFormatJapanese(Date.now()))
    }
}

export const checkDistanceService = new CheckDistanceService()
