import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from "react"
import {
    getPreSignedUrlObjectsForGetUserPictures,
    ResponseGetPicture,
} from "@/repositories/awsRepository"
import { ApplicationContext } from "./ApplicationContextProvider"
import { useLocation } from "react-router-dom"
import { convertToPicturesSortedByDate } from "@/functions/convertToPicturesSortedByDate"
import { convertToPicturesSortedByJinja } from "@/functions/convertToPicturesSortedByJinja"
import { cloneDeep } from "lodash"

export type UserPicture = ResponseGetPicture & {
    isSelect: boolean
}

export type AlbumContextValue = Readonly<{
    isShowHistoryPopup: boolean
    setIsShowHistoryPopup: Dispatch<SetStateAction<boolean>>
    userPictures: UserPicture[]
    setUserPictures: Dispatch<SetStateAction<UserPicture[]>>
    userPicturesDaySort: UserPicture[]
    setUserPicturesDaySort: Dispatch<SetStateAction<UserPicture[]>>
    userPicturesJinjaSort: UserPicture[]
    setUserPicturesJinjaSort: Dispatch<SetStateAction<UserPicture[]>>
    isDaySort: boolean
    setIsDaySort: Dispatch<SetStateAction<boolean>>
    selectPictureIndex: number
    setSelectPictureIndex: Dispatch<SetStateAction<number>>
}>

export const AlbumContext = createContext<AlbumContextValue | null>(null)
type Props = Readonly<{ children: ReactNode }>
export const AlbumContextProvider: React.FC<Props> = ({ children }) => {
    const [isShowHistoryPopup, setIsShowHistoryPopup] = useState(false)
    const [userPictures, setUserPictures] = useState<UserPicture[]>([])
    const [userPicturesDaySort, setUserPicturesDaySort] = useState<UserPicture[]>([])
    const [userPicturesJinjaSort, setUserPicturesJinjaSort] = useState<UserPicture[]>([])
    const [isDaySort, setIsDaySort] = useState(true)
    const [selectPictureIndex, setSelectPictureIndex] = useState<number>(0)
    const location = useLocation()
    const [isCashed, setIsCashed] = useState(false)

    const { setIsSelectMode } = useContext(ApplicationContext)!

    useEffect(() => {
        setUserPicturesDaySort(convertToPicturesSortedByDate(cloneDeep(userPictures)).flat())
        setUserPicturesJinjaSort(convertToPicturesSortedByJinja(cloneDeep(userPictures)).flat())
    }, [userPictures])

    useEffect(() => {
        if (
            location.pathname !== "/" &&
            location.pathname !== "/app/top" &&
            location.pathname !== "/app/download" &&
            !isCashed
        ) {
            getPreSignedUrlObjectsForGetUserPictures().then((responses: ResponseGetPicture[]) => {
                const newUserPictures = responses.map((res) => {
                    const newUserPicture: UserPicture = {
                        ...res,
                        isSelect: false,
                    }
                    return newUserPicture
                })
                setUserPictures(newUserPictures)
            })
            setIsSelectMode(false)
            setIsCashed(true)
        } else if (
            (location.pathname === "/" ||
                location.pathname === "/app/top" ||
                location.pathname === "/app/download") &&
            isCashed
        ) {
            setIsCashed(false)
        }
    }, [location.pathname])

    return (
        <AlbumContext.Provider
            value={{
                isShowHistoryPopup,
                setIsShowHistoryPopup,
                userPictures,
                setUserPictures,
                userPicturesDaySort,
                setUserPicturesDaySort,
                userPicturesJinjaSort,
                setUserPicturesJinjaSort,
                isDaySort,
                setIsDaySort,
                selectPictureIndex,
                setSelectPictureIndex,
            }}
        >
            {children}
        </AlbumContext.Provider>
    )
}
