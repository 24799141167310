import { useContext, useEffect } from "react"
import { Outlet } from "react-router-dom"
import { ApplicationContext } from "../contexts/ApplicationContextProvider"
import { csrfManager } from "../csrf/CsrfManager"
import { getJinjaList } from "@/repositories/jinjaRepository"
import { getTourList } from "@/repositories/tourRepository"
import { BottomNavigation } from "./BottomNavigation"
import {
    getFcmToken,
    getUserBadgeInfo,
    getUserStatus,
    saveFcmToken,
    updateLoginTime,
} from "@/repositories/userRepository"
import { getToken } from "firebase/messaging"
import { messaging } from "../firebaseConfig"
import { miyachiBadgeObjArray } from "@/functions/swalPopups/miyachiBadgeAcquisitionAnimation"

export const AppCommon = () => {
    const { setJinjaList, setTourList } = useContext(ApplicationContext)!

    useEffect(() => {
        csrfManager.setCsrf()
        getUserStatus().then((res) => {
            pendo.initialize({
                visitor: {
                    id: res.oid,
                },
            })
        })
        getJinjaList().then((jinjaList) => setJinjaList(jinjaList))
        getTourList().then((tourList) => setTourList(tourList))
        updateLoginTime()
    }, [])

    useEffect(() => {
        getUserBadgeInfo().then((res) => {
            const originalAcquiredSanpaiBadgeList = [1, 3, 10, 20, 30, 41]
            const filteredSanpaiBadgeList = originalAcquiredSanpaiBadgeList.filter(
                (number) => number <= res.numberOfVisitJinja
            )
            window.localStorage.setItem(
                "acquiredSanpaiBadgeList",
                JSON.stringify(filteredSanpaiBadgeList)
            )

            const filteredMiyachiBadgeList = miyachiBadgeObjArray.filter((obj, index) => {
                return res.numberOfEachJinjaVisited[index] >= obj.maximumCount
            })
            const mappedMiyachiBadgeList = filteredMiyachiBadgeList.map((obj) => obj.name)
            window.localStorage.setItem(
                "acquiredMiyachiBadgeList",
                JSON.stringify(mappedMiyachiBadgeList)
            )
        })
    }, [])

    useEffect(() => {
        const requestNotificationPermission = () => {
            if ("Notification" in window) {
                window.Notification.requestPermission().then((permission) => {
                    if (permission === "granted") {
                        getFcmToken().then((token) => {
                            if (token === null) {
                                getToken(messaging, {
                                    vapidKey: import.meta.env.VITE_FCM_PUBLIC_KEY,
                                }).then((token) => {
                                    saveFcmToken(token)
                                })
                            }
                        })
                    }
                })
            } else {
                console.error("このブラウザは通知をサポートしていません")
            }
        }
        if (csrfManager.csrf) {
            requestNotificationPermission()
        }
    }, [csrfManager.csrf])

    return (
        <div>
            <Outlet />
            <BottomNavigation />
        </div>
    )
}
