import styles from "./AlbumPage.module.scss"
import React, { useContext, useEffect, useState } from "react"
import plusButton from "@/assets/plusButton.svg"
import calenderIcon from "@/assets/albumPage/calenderIcon.svg"
import toriiIcon from "@/assets/albumPage/toriiIcon.svg"
import loadingTextIcon from "@/assets/albumPage/loadingTextIcon.svg"
import { HistoryOfVisitWithToastPopup } from "@/components/HistoryOfVisits"
import { SinglePhotoView } from "@/components/SinglePhotoView"
import { ApplicationContext } from "../contexts/ApplicationContextProvider"
import { AlbumContext } from "../contexts/AlbumContextProvider"
import { isIphone } from "@/functions/isIphone"
import kurukuruIcon from "@/assets/kurukuruIcon.svg"
import { SortPhotosArea } from "@/components/SortPhotosArea"

export const AlbumPage = () => {
    const {
        isSelectMode,
        setIsSelectMode,
        setNumberOfSelectedPictures,
        singlePhotoViewMode,
        areaHeight,
    } = useContext(ApplicationContext)!

    const {
        isShowHistoryPopup,
        setIsShowHistoryPopup,
        userPictures,
        setUserPictures,
        isDaySort,
        setIsDaySort,
    } = useContext(AlbumContext)!

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        sessionStorage.setItem("scrollPosition", "0")
        return () => {
            setIsSelectMode(false)
            setNumberOfSelectedPictures(0)
            setUserPictures((prevState) =>
                prevState.map((resetPicture) => {
                    resetPicture.isSelect = false
                    return resetPicture
                })
            )
        }
    }, [])

    const generateExistPicture = () => {
        return (
            <>
                <div className={styles.sortPhotosArea}>
                    <SortPhotosArea />
                </div>
                {isSelectMode ? (
                    <button
                        onClick={() => {
                            setIsSelectMode(false)
                            setNumberOfSelectedPictures(0)
                            setUserPictures((prevState) =>
                                prevState.map((resetPicture) => {
                                    resetPicture.isSelect = false
                                    return resetPicture
                                })
                            )
                        }}
                        className={styles.cancelButton}
                    >
                        キャンセル
                    </button>
                ) : (
                    <button
                        onClick={() => {
                            setIsSelectMode(true)
                        }}
                        className={styles.selectButton}
                    >
                        選択
                    </button>
                )}
            </>
        )
    }

    return (
        <article
            className={`${styles.albumPageArea}`}
            style={isIphone() ? { height: `${areaHeight}px` } : undefined}
        >
            <div>
                <h2>アルバム</h2>
                <nav>
                    <div className={`${isDaySort ? styles.isSortActive : ""}`}>
                        <button
                            onClick={() => {
                                setIsDaySort(true)
                            }}
                            disabled={isSelectMode}
                        >
                            日ごと
                        </button>
                        <hr />
                    </div>
                    <div className={`${isDaySort ? "" : styles.isSortActive}`}>
                        <button
                            onClick={() => {
                                setIsDaySort(false)
                            }}
                            disabled={isSelectMode}
                        >
                            神社ごと
                        </button>
                        <hr />
                    </div>
                </nav>
                {userPictures.length === 0 ? (
                    <div className={styles.emptyIconArea}>
                        {isDaySort ? (
                            <img src={calenderIcon} alt="calenderIcon" />
                        ) : (
                            <img src={toriiIcon} alt="toriiIcon" />
                        )}
                        <p>写真をアップロードしませんか？</p>
                    </div>
                ) : (
                    generateExistPicture()
                )}
                {!isSelectMode && (
                    <button
                        className={styles.plusButton}
                        onClick={() => {
                            setIsShowHistoryPopup(true)
                        }}
                    >
                        <img src={plusButton} alt="plusButton" />
                    </button>
                )}

                {isShowHistoryPopup && (
                    <HistoryOfVisitWithToastPopup
                        setIsShowPopup={setIsShowHistoryPopup}
                        setUserPictures={setUserPictures}
                        setIsLoading={setIsLoading}
                    />
                )}
                {isLoading && (
                    <div className={styles.loadingArea}>
                        <img src={kurukuruIcon} alt={"kurukuruIcon"} />
                        <img src={loadingTextIcon} alt={"loadingTextIcon"} />
                    </div>
                )}
                {singlePhotoViewMode === "album" && userPictures.length > 0 && <SinglePhotoView />}
            </div>
        </article>
    )
}
