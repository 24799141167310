import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"

export const axiosGet = async (
    url: string,
    config?: AxiosRequestConfig | undefined
): Promise<AxiosResponse> => {
    return await axios.get(url, config).catch((error: AxiosError) => {
        if (error.response && error.response.status === 401) {
            window.location.href = "/app/top"
            throw new Error("http client axiosGet error.")
        }
        throw error
    })
}

export const axiosPost = async (
    url: string,
    data: unknown,
    config?: AxiosRequestConfig | undefined
): Promise<AxiosResponse> => {
    return await axios.post(url, data, config).catch((error: AxiosError) => {
        if (error.response && error.response.status === 401) {
            window.location.href = "/app/top"
            throw new Error("http client axiosPost error.")
        }
        throw error
    })
}

export const axiosPut = async (
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig | undefined
): Promise<AxiosResponse> => {
    return await axios.put(url, data, config).catch((error) => {
        if (error.response && error.response.status === 401) {
            window.location.href = "/app/top"
            throw new Error("http client axiosPut error.")
        }
        throw error
    })
}

export const axiosDelete = async (
    url: string,
    config?: AxiosRequestConfig | undefined
): Promise<AxiosResponse> => {
    return await axios.delete(url, config).catch((error) => {
        if (error.response && error.response.status === 401) {
            window.location.href = "/app/top"
            throw new Error("http client axiosDelete error.")
        }
        throw error
    })
}
