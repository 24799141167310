import Swal from "sweetalert2"
import exclamationIcon from "@/assets/exclamationIcon.svg"
import errorIcon from "@/assets/errorIcon.svg"
import kurukuruIcon from "@/assets/kurukuruIcon.svg"
import { publishUserPicture } from "@/repositories/userRepository"
import { UserPicture } from "../../contexts/AlbumContextProvider"
import { Dispatch, SetStateAction } from "react"
import styles from "./swalFires.module.scss"

export const photoPublishPopup = (
    pictureId: string,
    setUserPictures: Dispatch<SetStateAction<UserPicture[]>>
) => {
    Swal.fire({
        html:
            `<img alt='exclamationIcon' src=${exclamationIcon} />` +
            "<h3>写真を公開しますか？</h3>" +
            "<p>※利用規約に違反する投稿は<br/>削除される場合があります</p>" +
            "<div>" +
            `<button id="photoPublish_cancelButton">キャンセル</button>` +
            `<button id="photoPublish_confirmButton">公開</button>` +
            "</div>",
        showConfirmButton: false,
        allowOutsideClick: false,
        customClass: {
            popup: styles.publicPhotoPopup,
            htmlContainer: styles.htmlContainer,
        },
        didOpen: () => {
            const cancelButton = document.getElementById("photoPublish_cancelButton")
            cancelButton!.onclick = () => {
                Swal.clickCancel()
            }
            const confirmButton = document.getElementById("photoPublish_confirmButton")
            confirmButton!.onclick = () => {
                Swal.clickConfirm()
            }
        },
    }).then((result) => {
        if (result.isConfirmed) {
            Swal.fire({
                html: `<img alt='kurukuruIcon' src=${kurukuruIcon} />` + "<h3>写真を共有中...</h3>",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                customClass: {
                    popup: styles.loadingPopup,
                    htmlContainer: styles.htmlContainer,
                },
            })

            publishUserPicture(pictureId, true)
                .then(() => {
                    setUserPictures((prevState) => {
                        const selectPictureIndex = prevState.findIndex((picture) => {
                            return picture.pictureId === pictureId
                        })
                        prevState[selectPictureIndex].publishFlag = true
                        return [...prevState]
                    })
                    Swal.close()
                })
                .catch(() => {
                    Swal.fire({
                        html:
                            `<img alt='errorIcon' src=${errorIcon} />` +
                            "<h3>写真の公開ができません</h3>" +
                            "<p>再度お試しください</p>" +
                            "<button  id='photoPublishPopup_closeButton'>閉じる</button>",
                        showConfirmButton: false,
                        customClass: {
                            popup: styles.publishErrorPopup,
                            htmlContainer: styles.htmlContainer,
                        },
                        didOpen: () => {
                            const closeButton = document.getElementById(
                                "photoPublishPopup_closeButton"
                            )
                            closeButton!.onclick = () => {
                                Swal.close()
                            }
                        },
                    })
                })
        }
    })
}
