import { initializeApp } from "firebase/app"
import { getMessaging } from "firebase/messaging"

const firebaseConfig = {
    apiKey: "AIzaSyDYSuT14e3J70tb5VC2AQsV2KoF2S-47zQ",
    authDomain: "digmaps-423500.firebaseapp.com",
    projectId: "digmaps-423500",
    storageBucket: "digmaps-423500.appspot.com",
    messagingSenderId: "262061093748",
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: "G-RJBS2MN3V8",
}

const app = initializeApp(firebaseConfig)
export const messaging = getMessaging(app)
