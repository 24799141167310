import { JinjaInfo } from "@/repositories/jinjaRepository"

export const jinjaInfoBuilder = (value: Partial<JinjaInfo>) => {
    return {
        detailInfo: "",
        address: "",
        phoneNumber: "",
        receptionInfo: "",
        officialHp: "",
        restRoom: "",
        parking: "",
        mapLink: "",
        tripGuideLink: "",
        ...value,
    }
}
