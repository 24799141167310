import { Dispatch, SetStateAction } from "react"
import { UserPicture } from "../../contexts/AlbumContextProvider"
import Swal from "sweetalert2"
import kurukuruIcon from "@/assets/kurukuruIcon.svg"
import styles from "@/functions/swalPopups/swalFires.module.scss"
import { publishUserPicture } from "@/repositories/userRepository"
import { cloneDeep } from "lodash"
import errorIcon from "@/assets/errorIcon.svg"

export const suspendedPhotoPublishPopup = (
    pictureId: string,
    setUserPictures: Dispatch<SetStateAction<UserPicture[]>>
) => {
    Swal.fire({
        html: `<img alt='kurukuruIcon' src=${kurukuruIcon} />` + "<h3>写真を公開停止中...</h3>",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
            popup: styles.loadingPopup,
            htmlContainer: styles.htmlContainer,
        },
    })

    publishUserPicture(pictureId, false)
        .then(() => {
            setUserPictures((prevState) => {
                const selectPictureIndex = prevState.findIndex((picture) => {
                    return picture.pictureId === pictureId
                })
                prevState[selectPictureIndex].publishFlag = false
                return cloneDeep(prevState)
            })
            Swal.close()
        })
        .catch(() => {
            Swal.fire({
                html:
                    `<img alt='errorIcon' src=${errorIcon} />` +
                    "<h3>写真の公開停止ができません</h3>" +
                    "<p>再度お試しください</p>" +
                    "<button  id='suspendedPhotoPublishPopup_closeButton'>閉じる</button>",
                showConfirmButton: false,
                customClass: {
                    popup: styles.publishErrorPopup,
                    htmlContainer: styles.htmlContainer,
                },
                didOpen: () => {
                    const closeButton = document.getElementById(
                        "suspendedPhotoPublishPopup_closeButton"
                    )
                    closeButton!.onclick = () => {
                        Swal.close()
                    }
                },
            })
        })
}
