import { createDateFormat } from "./createDate"
import { UserPicture } from "../contexts/AlbumContextProvider"

export const convertToPicturesSortedByDate = (pictures: UserPicture[]) => {
    const visitDays = pictures
        .sort((a, b) => b.visitDate - a.visitDate)
        .map((picture) => createDateFormat(picture.visitDate))
    const filteredVisitDays = Array.from(new Set(visitDays))

    return filteredVisitDays.map((filteredVisitDay) =>
        pictures
            .filter((picture) => createDateFormat(picture.visitDate) === filteredVisitDay)
            .sort((a, b) => b.registrationDate - a.registrationDate)
    )
}
