export const badgeProgressStyle = (numberOfVisit: number, maximumCount: number) => {
    let gradientPercent = 0
    if (maximumCount !== 0) {
        gradientPercent = (numberOfVisit / maximumCount) * 100
    }
    return {
        background: `conic-gradient(#FB5B01 ${gradientPercent}%, #D9D9D9 0)`,
        maskImage: "radial-gradient(circle, transparent 62%, black 60%)",
    }
}
