import styles from "./swalFires.module.scss"
import Swal from "sweetalert2"
import checkInIcon from "@/assets/checkInIcon.svg"
import { sanpaiBadgeAcquisitionAnimation } from "@/functions/swalPopups/sanpaiBadgeAcquisitionAnimation"

export const checkInPopup = (
    nearJinja: string,
    numberOfVisitJinja: number,
    numberOfEachJinjaVisited: number[]
) => {
    Swal.fire({
        html:
            `<img alt="checkIn" src=${checkInIcon} />` +
            `<h3>${nearJinja}に<br/>参拝しました</h3>` +
            `<h4>参拝神社数&nbsp;&nbsp;${numberOfVisitJinja}/41</h4>` +
            `<button class=${styles.closeButton} id="checkInPopup_closeButton">閉じる<button/>`,
        showConfirmButton: false,
        allowOutsideClick: false,
        customClass: {
            popup: styles.checkInPopup,
            htmlContainer: styles.htmlContainer,
        },
        didOpen: () => {
            const closeButton = document.getElementById("checkInPopup_closeButton")
            closeButton!.onclick = () => {
                Swal.close()
                sanpaiBadgeAcquisitionAnimation(numberOfVisitJinja, numberOfEachJinjaVisited)
            }
        },
    })
}
