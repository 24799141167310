import Swal from "sweetalert2"
import styles from "./swalFires.module.scss"
import badgeOf1st from "@/assets/myPage/1stBadge.png"
import badgeOf3rd from "@/assets/myPage/3rdBadge.png"
import badgeOf10th from "@/assets/myPage/10thBadge.png"
import badgeOf20th from "@/assets/myPage/20thBadge.png"
import badgeOf30th from "@/assets/myPage/30thBadge.png"
import badgeOfComplete from "@/assets/myPage/completeBadge.png"
import { miyachiBadgeAcquisitionAnimation } from "@/functions/swalPopups/miyachiBadgeAcquisitionAnimation"

const sanpaiMap: { [key: number]: string[] } = {
    1: ["一社参拝", "badgeOf1st", badgeOf1st],
    3: ["三社参拝", "badgeOf3rd", badgeOf3rd],
    10: ["十社参拝", "badgeOf10th", badgeOf10th],
    20: ["二十社参拝", "badgeOf20th", badgeOf20th],
    30: ["三十社参拝", "badgeOf30th", badgeOf30th],
    41: ["全社参拝", "badgeOfComplete", badgeOfComplete],
}

export const sanpaiBadgeAcquisitionAnimation = (
    numberOfVisitJinja: number,
    numberOfEachJinjaVisited: number[]
) => {
    const isPortrait = () => {
        return window.innerHeight > window.innerWidth
    }

    if (sanpaiMap[numberOfVisitJinja]) {
        const acquiredBadgeListString = localStorage.getItem("acquiredSanpaiBadgeList")
        const acquiredBadgeList = acquiredBadgeListString ? JSON.parse(acquiredBadgeListString) : []
        if (!acquiredBadgeList.includes(numberOfVisitJinja)) {
            acquiredBadgeList.push(numberOfVisitJinja)
            localStorage.setItem("acquiredSanpaiBadgeList", JSON.stringify(acquiredBadgeList))
            Swal.fire({
                html:
                    `<div class=${isPortrait() ? styles.imgAreaVerticalVersion : styles.imgAreaHorizontalVersion}>` +
                    `<img alt=${sanpaiMap[numberOfVisitJinja][1]} src=${sanpaiMap[numberOfVisitJinja][2]} />` +
                    `</div>` +
                    `<div>` +
                    `<h3>バッジ獲得！</h3>` +
                    `<h3>${sanpaiMap[numberOfVisitJinja][0]}</h3>` +
                    `</div>` +
                    `<div>` +
                    `<p>これまで獲得したバッジは</p>` +
                    `<p>「マイページ」から確認できます</p>` +
                    `</div>`,
                showConfirmButton: false,
                animation: false,
                customClass: {
                    container: styles.badgeAcquisitionAnimationContainer,
                    popup: styles.badgeAcquisitionAnimationPopup,
                    htmlContainer: styles.htmlContainer,
                },
                didOpen: () => {
                    const swal2Container = document.getElementsByClassName(
                        "swal2-container"
                    )[0] as HTMLDivElement
                    swal2Container.onclick = () => {
                        Swal.close()
                        miyachiBadgeAcquisitionAnimation(numberOfEachJinjaVisited)
                    }
                },
            })
        } else {
            miyachiBadgeAcquisitionAnimation(numberOfEachJinjaVisited)
        }
    } else {
        miyachiBadgeAcquisitionAnimation(numberOfEachJinjaVisited)
    }
}
