import { CloseIcon } from "@/components/icon/CloseIcon"
import styles from "./TermsPopup.module.scss"
import { Terms } from "./Terms"

type Props = {
    setIsTermsPopup: (isTermsPopup: boolean) => void
}

export const TermsPopup = (props: Props) => {
    return (
        <div className={styles.modalArea}>
            <div>
                <button
                    onClick={() => {
                        props.setIsTermsPopup(false)
                    }}
                >
                    <CloseIcon />
                </button>
                <Terms />
            </div>
        </div>
    )
}
