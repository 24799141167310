import styles from "./CloseIcon.module.scss"

export const CloseIcon = () => {
    return (
        <div className={styles.closeIcon}>
            <div></div>
            <div></div>
        </div>
    )
}
