import styles from "../pages/EventPage.module.scss"

const calendarAreaHeight = 318

export class EventPageService {
    private startY = 0
    private startYDiff = 0
    private startScrollTop = 0
    private endY = 0
    private startClassName = ""

    getProperty() {
        return {
            startY: this.startY,
            startYDiff: this.startYDiff,
            startScrollTop: this.startScrollTop,
            endY: this.endY,
            startClassName: this.startClassName,
        }
    }

    setProperty(reactTouchEvent: React.TouchEvent<HTMLDivElement>) {
        this.startClassName = reactTouchEvent.currentTarget.className
        if (this.startClassName.includes("topUpAnime")) {
            this.startYDiff = reactTouchEvent.targetTouches[0].clientY
        } else {
            this.startYDiff = calendarAreaHeight - reactTouchEvent.targetTouches[0].clientY
        }
        this.startScrollTop = reactTouchEvent.currentTarget.children[2].scrollTop
        this.startY = reactTouchEvent.targetTouches[0].clientY
        this.endY = reactTouchEvent.targetTouches[0].clientY
    }

    changeStyleAndSetPositionY(
        reactTouchEvent: React.TouchEvent<HTMLDivElement>,
        month: number,
        bottomNavigationHeight: number
    ) {
        if (this.startClassName.includes("topUpAnime")) {
            if (this.startY - reactTouchEvent.targetTouches[0].clientY <= 0) {
                if (reactTouchEvent.currentTarget.children[2].scrollTop <= 0) {
                    reactTouchEvent.currentTarget.className = styles.eventDetailViewArea
                    reactTouchEvent.currentTarget.style.height = `${window.innerHeight - reactTouchEvent.targetTouches[0].clientY - bottomNavigationHeight - 24 + this.startYDiff + this.startScrollTop}px`
                    this.endY = reactTouchEvent.targetTouches[0].clientY
                }
            }
        } else {
            reactTouchEvent.currentTarget.className = styles.eventDetailViewArea
            this.endY = reactTouchEvent.targetTouches[0].clientY
            if (month === 8 || month === 11) {
                reactTouchEvent.currentTarget.style.height = `${window.innerHeight - reactTouchEvent.targetTouches[0].clientY - bottomNavigationHeight - 24 - this.startYDiff - 42}px`
            } else {
                reactTouchEvent.currentTarget.style.height = `${window.innerHeight - reactTouchEvent.targetTouches[0].clientY - bottomNavigationHeight - 24 - this.startYDiff}px`
            }
        }
    }

    setAnimeClass(reactTouchEvent: React.TouchEvent<HTMLDivElement>, month: number) {
        if (this.startY !== this.endY) {
            if (this.startClassName.includes("topUpAnime")) {
                if (this.endY - this.startY > 100) {
                    if (month === 8 || month === 11) {
                        reactTouchEvent.currentTarget.className = `${styles.eventDetailViewArea} ${styles.down6Anime}`
                    } else {
                        reactTouchEvent.currentTarget.className = `${styles.eventDetailViewArea} ${styles.down5Anime}`
                    }
                } else {
                    reactTouchEvent.currentTarget.className = `${styles.eventDetailViewArea} ${styles.topUpAnime}`
                }
            } else {
                if (this.startY - this.endY > 100) {
                    reactTouchEvent.currentTarget.className = `${styles.eventDetailViewArea} ${styles.topUpAnime}`
                } else {
                    if (month === 8 || month === 11) {
                        reactTouchEvent.currentTarget.className = `${styles.eventDetailViewArea} ${styles.down6Anime}`
                    } else {
                        reactTouchEvent.currentTarget.className = `${styles.eventDetailViewArea} ${styles.down5Anime}`
                    }
                }
            }
        }
    }
}

export const eventPageService = new EventPageService()
