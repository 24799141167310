export const createDateFormat = (unixTimeSec: number) => {
    const timeToDate: Date = new Date(unixTimeSec)

    return timeToDate.toLocaleString("ja-JP", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    })
}

export const createDateFormatJapanese = (unixTimeSec: number) => {
    const timeToDate: string = createDateFormat(unixTimeSec)

    const [year, month, day] = timeToDate.split(/\/|\s/)
    return `${year}年${month}月${day}日`
}

export const historyDateFormat = (unixTimeSec: number) => {
    return (
        Intl.DateTimeFormat("ja-JP", { month: "short" }).format(new Date(unixTimeSec)) +
        "," +
        new Date(unixTimeSec).getFullYear()
    )
}
