import { Jinja } from "./jinjaRepository"
import { axiosGet } from "../clients/httpClient"
import { AxiosResponse } from "axios"

export type Tour = {
    tourName: string
    tourNameKana: string
    orderNumber: number
    jinjaList: Jinja[]
}

export const getTourList = async (): Promise<Tour[]> => {
    const { data } = (await axiosGet("/api/tour/tour-list")) as AxiosResponse
    return data
}
