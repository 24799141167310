import { BackIcon } from "@/components/icon/BackIcon"
import React, { useContext, useEffect, useRef } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { ApplicationContext } from "../contexts/ApplicationContextProvider"
import { SinglePhotoView } from "@/components/SinglePhotoView"
import styles from "./JinjaPhotoLibraryPage.module.scss"
import { isIphone } from "@/functions/isIphone"
import { AlbumContext, UserPicture } from "../contexts/AlbumContextProvider"
import { convertJinjaPictureAndUserPicture } from "@/services/convertJinjaPictureAndUserPicture"
import defaultUserIcon from "@/assets/defaultUserIcon.svg"
import { jinjaImageList } from "@/services/JinjaPhotoImageList"

export const JinjaPhotoLibraryPage = () => {
    const navigate = useNavigate()
    const jinjaIdOrUndefined = useParams().jinjaId
    const jinjaId = jinjaIdOrUndefined ? jinjaIdOrUndefined : ""
    const location = useLocation()
    const state = location.state as {
        orderNumber: number
        tourName: string
        tourNameKana: string
        selectJinjaName: string
        selectJinjaNameKana: string
        jinjaPictures: UserPicture[]
    }
    const { selectPictureIndex, setSelectPictureIndex } = useContext(AlbumContext)!

    const { setJinjaPictures, singlePhotoViewMode, setSinglePhotoViewMode, areaHeight } =
        useContext(ApplicationContext)!
    const libraryPageAreaRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        convertJinjaPictureAndUserPicture(jinjaId, setJinjaPictures)
    }, [])
    useEffect(() => {
        const jinjaNumber = parseInt(jinjaId.slice(-2), 10)
        if (libraryPageAreaRef.current) {
            libraryPageAreaRef.current.style.background = `linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%), url(${jinjaImageList[jinjaNumber]}), lightgray 50%`
            libraryPageAreaRef.current.style.backgroundPosition = "center"
            libraryPageAreaRef.current.style.backgroundSize = "cover"
            libraryPageAreaRef.current.style.backgroundAttachment = "fixed"
        }
    }, [])

    return (
        <div
            ref={libraryPageAreaRef}
            className={`${styles.libraryPageArea}`}
            style={isIphone() ? { height: `${areaHeight}px` } : undefined}
        >
            <button onClick={() => navigate(-1)}>
                <BackIcon />
            </button>
            <h3>
                {state.orderNumber}.<ruby data-ruby={state.tourNameKana}>{state.tourName}</ruby>
            </h3>
            <h2>
                <ruby data-ruby={state.selectJinjaNameKana}>{state.selectJinjaName}</ruby>
            </h2>
            <div className={styles.jinjaPicturesArea}>
                {state.jinjaPictures?.map((picture, index) => {
                    return (
                        <div
                            key={`photo-library-${index}`}
                            className={styles.jinjaPicture}
                            onClick={() => {
                                setSinglePhotoViewMode("jinjaDetail")
                                setSelectPictureIndex(index)
                            }}
                        >
                            <img key={index} src={picture.getUrl} alt={`jinjaAlbumImage${index}`} />
                            {picture.userIcon !== "noIcon" && (
                                <>
                                    <div className={styles.pictureBackgroundImage}></div>
                                    <img
                                        src={
                                            picture.userIcon === "defaultIcon"
                                                ? defaultUserIcon
                                                : picture.userIcon
                                        }
                                        alt="userIcon"
                                        className={styles.userIcon}
                                    />
                                </>
                            )}
                        </div>
                    )
                })}
            </div>
            {singlePhotoViewMode === "jinjaDetail" && selectPictureIndex !== undefined && (
                <SinglePhotoView />
            )}
        </div>
    )
}
