import React, { ComponentType, forwardRef, useRef } from "react"
import blackBackButton from "@/assets/blackBackButton.svg"
import styles from "./withToastPopup.module.scss"
import { swipeCheckService } from "@/services/SwipeCheckService"
import { WithToastPopupProps } from "../types/withToastPopupProps"
import { useLocation } from "react-router-dom"
import { isIphone } from "@/functions/isIphone"

export const withToastPopup = <T,>(WrappedComponent: ComponentType<T>) =>
    forwardRef((props: T & WithToastPopupProps) => {
        const toastPopupAreaRef = useRef<HTMLDivElement>(null)
        const location = useLocation()

        const isAlbumPage = () => {
            return location.pathname === "/app/album"
        }

        const closeModal = () => {
            setTimeout(() => {
                props.setIsShowPopup(false)
            }, 500)
            toastPopupAreaRef.current!.className = `${styles.toastPopupArea} ${styles.slideOutToBottom}`
        }

        return (
            <>
                <div className={styles.spacer}></div>
                <div
                    ref={toastPopupAreaRef}
                    className={`${styles.toastPopupArea} ${styles.slideInFromBottom}`}
                    style={isIphone() ? { height: `${window.innerHeight - 32}px` } : undefined}
                    onTouchStart={(e: React.TouchEvent<HTMLDivElement>) => {
                        swipeCheckService.setTouchStartY(e.targetTouches[0].clientY)
                    }}
                    onTouchMove={(e: React.TouchEvent<HTMLDivElement>) => {
                        swipeCheckService.setTouchEndY(e.targetTouches[0].clientY)
                    }}
                    onTouchEnd={(e: React.TouchEvent<HTMLDivElement>) => {
                        if (
                            swipeCheckService.compareStartWithEnd() &&
                            e.currentTarget.scrollTop <= 0
                        ) {
                            closeModal()
                        }
                    }}
                >
                    <div className={styles.backArea}>
                        <img
                            onClick={() => {
                                closeModal()
                            }}
                            src={blackBackButton}
                            alt="blackBackButton"
                        />
                        {isAlbumPage() && (
                            <h2 className={styles.albumTitle}>参拝履歴を選択してください</h2>
                        )}
                        {props.isBadge && <h2 className={styles.defaultTitle}>集めたバッジ</h2>}
                        {props.isQuestion && <h2 className={styles.defaultTitle}>よくある質問</h2>}
                        {props.isNews && <h2 className={styles.defaultTitle}>お知らせ</h2>}
                        {props.isHistoryOfVisit && (
                            <h2 className={styles.defaultTitle}>参拝履歴</h2>
                        )}
                        {props.isTerms && <h2 className={styles.defaultTitle}>利用規約</h2>}
                    </div>
                    <WrappedComponent {...props} />
                </div>
            </>
        )
    })
