import React, { Dispatch, SetStateAction, useEffect, useRef } from "react"

export interface BeforeInstallPromptEvent extends Event {
    platforms: string[]
    userChoice: Promise<{
        outcome: "accepted" | "dismissed"
        platform: string
    }>

    prompt(): Promise<void>
}

type Props = { isInstallable: boolean; setInstallable: Dispatch<SetStateAction<boolean>> }

export const InstallButton = (props: Props) => {
    const deferredPromptRef = useRef<BeforeInstallPromptEvent | null>(null)

    const beforeInstallPromptHandler = (e: Event) => {
        e.preventDefault()
        props.setInstallable(true)
        deferredPromptRef.current = e as BeforeInstallPromptEvent
    }

    const appInstalledHandler = () => {
        props.setInstallable(false)
    }

    useEffect(() => {
        window.addEventListener("beforeinstallprompt", beforeInstallPromptHandler)
        window.addEventListener("appinstalled", appInstalledHandler)
        return () => {
            window.removeEventListener("beforeinstallprompt", beforeInstallPromptHandler)
            window.removeEventListener("appinstalled", appInstalledHandler)
        }
    }, [])

    const handleClickInstall = async () => {
        if (deferredPromptRef.current) {
            await deferredPromptRef.current.prompt()
            deferredPromptRef.current?.userChoice.then((value) => {
                if (value.outcome === "accepted") {
                    props.setInstallable(true)
                }
            })
        }
    }

    return (
        <button disabled={!props.isInstallable} onClick={handleClickInstall}>
            ホーム画面にアプリを追加
        </button>
    )
}
