import { BackIcon } from "@/components/icon/BackIcon"
import publishedIcon from "@/assets//albumPage/publishedIcon.svg"
import styles from "./SinglePhotoView.module.scss"
import { useContext, useEffect, useRef } from "react"
import { Zoom } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import { type Swiper as SwiperClass } from "swiper/types"
import { ApplicationContext } from "../contexts/ApplicationContextProvider"
import { isIphone } from "@/functions/isIphone"
import "swiper/css/zoom"
import { AlbumContext } from "../contexts/AlbumContextProvider"
import { photoPublishPopup } from "@/functions/swalPopups/photoPublishPopup"
import { suspendedPhotoPublishPopup } from "@/functions/swalPopups/suspendedPhotoPublishPopup"
import defaultUserIcon from "@/assets/defaultUserIcon.svg"

export const SinglePhotoView = () => {
    const { jinjaPictures, singlePhotoViewMode, setSinglePhotoViewMode, areaHeight } =
        useContext(ApplicationContext)!
    const {
        isDaySort,
        userPicturesDaySort,
        userPicturesJinjaSort,
        selectPictureIndex,
        setUserPictures,
        setSelectPictureIndex,
    } = useContext(AlbumContext)!
    const ref = useRef<HTMLDivElement>(null)

    const determineSortDateOrJinja = () => {
        if (singlePhotoViewMode === "album") {
            if (isDaySort) {
                return userPicturesDaySort
            }
            return userPicturesJinjaSort
        }
        return jinjaPictures
    }

    useEffect(() => {
        return () => {
            setSinglePhotoViewMode(undefined)
        }
    }, [])
    const handleZoomChange = (swiper: SwiperClass, scale: number) => {
        if (scale !== 1) {
            swiper.allowSlidePrev = false
            swiper.allowSlideNext = false
        } else {
            swiper.allowSlidePrev = true
            swiper.allowSlideNext = true
        }
    }

    const publishButton = () => {
        if (determineSortDateOrJinja()[selectPictureIndex].publishFlag) {
            return (
                <button
                    className={styles.unPublicButton}
                    onClick={() => {
                        suspendedPhotoPublishPopup(
                            determineSortDateOrJinja()[selectPictureIndex].pictureId,
                            setUserPictures
                        )
                    }}
                >
                    公開停止
                </button>
            )
        } else {
            return (
                <button
                    className={styles.publicButton}
                    onClick={() => {
                        photoPublishPopup(
                            determineSortDateOrJinja()[selectPictureIndex].pictureId,
                            setUserPictures
                        )
                    }}
                >
                    公開
                </button>
            )
        }
    }
    return (
        <div
            className={styles.singlePhotoArea}
            style={isIphone() ? { height: `${areaHeight}px` } : undefined}
        >
            <button
                onClick={() => {
                    setSinglePhotoViewMode(undefined)
                }}
                className={styles.backButton}
            >
                <BackIcon />
            </button>
            {window.location.pathname === "/app/album" && publishButton()}
            <Swiper
                zoom={true}
                modules={[Zoom]}
                className={styles.singlePhoto}
                pagination={{ clickable: true, dynamicBullets: true }}
                slidesPerView={1}
                centeredSlides={true}
                initialSlide={selectPictureIndex}
                onSlideChange={(swiper) => {
                    setSelectPictureIndex(swiper.activeIndex)
                }}
                onZoomChange={handleZoomChange}
            >
                {determineSortDateOrJinja().map((picture, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <div className="swiper-zoom-container">
                                <div className={styles.mainImgWrapper}>
                                    <img
                                        src={picture.singlePhotoGetUrl}
                                        alt="singlePhoto"
                                        className={styles.mainImg}
                                        style={isIphone() ? { maxHeight: areaHeight } : undefined}
                                    />
                                </div>
                            </div>
                            {window.location.pathname === "/app/album"
                                ? picture.publishFlag && (
                                      <img
                                          src={publishedIcon}
                                          alt="publishedIcon"
                                          className={styles.publishedIcon}
                                      />
                                  )
                                : picture.userIcon !== "noIcon" && (
                                      <div className={styles.userIconArea} ref={ref}>
                                          <img
                                              src={
                                                  picture.userIcon === "defaultIcon"
                                                      ? defaultUserIcon
                                                      : picture.userIcon
                                              }
                                              alt="userIcon"
                                          />
                                          <p>{picture.userName}</p>
                                      </div>
                                  )}
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )
}
