import { CloseIcon } from "@/components/icon/CloseIcon"
import styles from "./KoremadenoMeguri.module.scss"
import map from "@/assets/map.svg"
import { Dispatch, RefObject, SetStateAction, useContext } from "react"
import { ApplicationContext } from "../contexts/ApplicationContextProvider"
import { Tour } from "@/repositories/tourRepository"

type Props = {
    setIsShowMap: Dispatch<SetStateAction<boolean>>
    toursAreaRef: RefObject<HTMLDivElement>
}

export const KoremadenoMeguri = (props: Props) => {
    const { tourList, histories, userIcon } = useContext(ApplicationContext)!

    const scrollToSelectedTour = (orderNumber: number) => {
        props.setIsShowMap(false)
        const currentScrollPositionY = props.toursAreaRef.current!.scrollTop
        const targetElementRelativePositionY =
            props.toursAreaRef.current!.children[orderNumber].getBoundingClientRect().top
        const tourAreaMarginTop = 78
        const rubyMargin = 16
        const targetElementAbsolutePositionY =
            currentScrollPositionY + targetElementRelativePositionY - tourAreaMarginTop - rubyMargin

        props.toursAreaRef.current!.scrollTo({
            top: targetElementAbsolutePositionY,
            behavior: "smooth",
        })
    }

    const generateButtonClassName = (tour: Tour) => {
        const isInclude = histories.some((history) =>
            tour.jinjaList[0].relativeJinjaList.includes(history.jinjaName + history.jinjaLastWord)
        )
        if (isInclude) return styles.visited
        return styles.unVisited
    }

    return (
        <div className={styles.modalArea}>
            <div className={styles.popupArea}>
                <div className={styles.popupTitle}>
                    <div>
                        <img alt={"userIcon"} src={userIcon} />
                        <h2>宮地マップ</h2>
                    </div>
                    <button
                        onClick={() => {
                            props.setIsShowMap(false)
                        }}
                    >
                        <CloseIcon />
                    </button>
                </div>
                <div className={styles.mapArea}>
                    <img src={map} alt="map" />
                    {tourList.map((tour) => {
                        return (
                            <button
                                key={`tourOrderNumber${tour.orderNumber}`}
                                onClick={() => scrollToSelectedTour(tour.orderNumber)}
                                className={generateButtonClassName(tour)}
                            >
                                {tour.orderNumber}
                            </button>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
