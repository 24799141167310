import { AxiosResponse } from "axios"
import { csrfManager } from "../csrf/CsrfManager"
import { axiosGet, axiosPost } from "../clients/httpClient"

export type HistoryOfVisit = {
    jinjaName: string
    jinjaLastWord: string
    visitDate: number
    jinjaId: string
    historyOfVisitId: string
}

export type ResponseHistoryOfVisitObj = {
    numberOfVisitJinja: number
    numberOfEachJinjaVisited: number[]
    historyOfVisits: HistoryOfVisit[]
}

export const getHistoryOfVisits = async (): Promise<HistoryOfVisit[]> => {
    const { data } = (await axiosGet("/api/user/history-of-visits")) as AxiosResponse
    return data
}

export const postHistoryOfVisit = async (jinjaName: string): Promise<ResponseHistoryOfVisitObj> => {
    const response: AxiosResponse = (await axiosPost(
        "/api/user/checkin-register",
        {
            name: jinjaName,
        },
        {
            headers: {
                "X-CSRF-TOKEN": csrfManager.getCsrf(),
            },
            timeout: 5000,
        }
    )) as AxiosResponse
    return response.data
}
