import Swal from "sweetalert2"
import styles from "./swalFires.module.scss"
import checkInIcon from "@/assets/checkInIcon.svg"

export const alreadyVisitedPopup = (jinja: string) => {
    Swal.fire({
        html:
            `<img alt='checkInIcon' src=${checkInIcon} />` +
            `<h3>本日、${jinja}は<br/>参拝済です</h3>` +
            "<p>他の神社も巡ってみましょう！</p>" +
            `<button class=${styles.closeButton} id='alreadyVisitedPopup_closeButton'>閉じる</button>`,
        showConfirmButton: false,
        allowOutsideClick: false,
        customClass: {
            popup: styles.alreadyVisitedPopup,
            htmlContainer: styles.htmlContainer,
        },
        didOpen: () => {
            const closeButton = document.getElementById("alreadyVisitedPopup_closeButton")
            closeButton!.onclick = () => {
                Swal.close()
            }
        },
    })
}
