import { axiosGet } from "../clients/httpClient"
import { AxiosResponse } from "axios"

class CsrfManager {
    csrf: string | undefined = undefined

    async setCsrf() {
        const res = (await axiosGet("/api/csrf")) as AxiosResponse
        this.csrf = res.data.token
    }

    getCsrf() {
        return this.csrf
    }
}

export const csrfManager = new CsrfManager()
