import styles from "./swalFires.module.scss"
import Swal from "sweetalert2"
import kurukuruIcon from "@/assets/kurukuruIcon.svg"
import { checkDistanceService } from "@/services/CheckDistanceService"
import { createDateFormatJapanese } from "../createDate"
import { postHistoryOfVisit, HistoryOfVisit } from "@/repositories/historyOfVisitRepository"
import disableCheckInIcon from "../../../public/bottomNavigation/disableCheckInIcon.svg"
import enableCheckInIcon from "../../../public/bottomNavigation/enableCheckInIcon.svg"
import { checkInPopup } from "./checkInPopup"
import { Jinja } from "@/repositories/jinjaRepository"
import React, { Dispatch, SetStateAction } from "react"
import { noSignalPopup } from "@/functions/swalPopups/noSignalPopup"
import { alreadyVisitedPopup } from "@/functions/swalPopups/alreadyVisitedPopup"
import { notNearbyJinjaPopup } from "@/functions/swalPopups/notNearbyJinjaPopup"
import { geolocationError1Popup } from "@/functions/swalPopups/geolocationError1Popup"
import { postCheckin } from "@/repositories/checkinRepository"
import { NavigateFunction } from "react-router-dom"

export const checkCurrentLocationPopup = (
    jinjaList: Jinja[],
    setHistories: Dispatch<SetStateAction<HistoryOfVisit[]>>,
    checkInImageElement: HTMLImageElement,
    navigate: NavigateFunction,
    setIsShowQuestionPopup: React.Dispatch<React.SetStateAction<boolean>>,
    setIsShowAnswer1: React.Dispatch<React.SetStateAction<boolean>>,
    setIsShowAnswer2: React.Dispatch<React.SetStateAction<boolean>>
) => {
    let startTime = 0
    let endTime = 0
    const toggleCheckInIconState = () => {
        checkInImageElement.src = disableCheckInIcon
        const button = checkInImageElement.parentElement as HTMLButtonElement
        button.disabled = true
        setTimeout(() => {
            checkInImageElement.src = enableCheckInIcon
            const button = checkInImageElement.parentElement as HTMLButtonElement
            button.disabled = false
        }, 5000)
    }

    const successCallback = async (position: {
        coords: { latitude: number; longitude: number }
    }) => {
        endTime = Date.now()
        toggleCheckInIconState()
        const getNearJinjaResult = await checkDistanceService.getNearJinja(
            {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
            },
            jinjaList
        )

        if (getNearJinjaResult.foundJinja) {
            const currentDate = createDateFormatJapanese(Date.now())
            if (currentDate !== localStorage.getItem(getNearJinjaResult.foundJinja.name)) {
                try {
                    const responseHistoryOfVisitObj = await postHistoryOfVisit(
                        getNearJinjaResult.foundJinja.name
                    )
                    checkDistanceService.registerArrivalDate(getNearJinjaResult.foundJinja.name)
                    setHistories(responseHistoryOfVisitObj.historyOfVisits)
                    postCheckin({
                        longitude: position.coords.longitude,
                        latitude: position.coords.latitude,
                        checkinAcquisitionTime: Date.now(),
                        gpsAcquisitionTime: endTime - startTime,
                        jinjaDiffAcquisitionTime: getNearJinjaResult.time,
                    })
                    checkInPopup(
                        `${getNearJinjaResult.foundJinja.name}${getNearJinjaResult.foundJinja.lastWord}`,
                        responseHistoryOfVisitObj.numberOfVisitJinja,
                        responseHistoryOfVisitObj.numberOfEachJinjaVisited
                    )
                } catch (e) {
                    console.error(e)
                    noSignalPopup()
                }
            } else {
                alreadyVisitedPopup(
                    `${getNearJinjaResult.foundJinja.name}${getNearJinjaResult.foundJinja.lastWord}`
                )
            }
        } else {
            notNearbyJinjaPopup()
            postCheckin({
                longitude: position.coords.longitude,
                latitude: position.coords.latitude,
                checkinAcquisitionTime: Date.now(),
                gpsAcquisitionTime: endTime - startTime,
                jinjaDiffAcquisitionTime: getNearJinjaResult.time,
            })
        }
    }

    const errorCallback = (error: GeolocationPositionError) => {
        toggleCheckInIconState()
        switch (error.code) {
            case 1:
                console.error("CASE1 : User denied the request for Geolocation.")
                geolocationError1Popup(
                    navigate,
                    setIsShowQuestionPopup,
                    setIsShowAnswer1,
                    setIsShowAnswer2
                )
                break
            case 2:
                console.error("CASE2 : Location information is unavailable.")
                noSignalPopup()
                break
            case 3:
                console.error("CASE3 : The request to get user location timed out.")
                noSignalPopup()
                break
            default:
                console.error(`DEFAULT : An unknown error occurred. ${error.code}`)
                noSignalPopup()
                break
        }
    }

    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    }

    Swal.fire({
        html: `<img alt='kurukuruIcon' src=${kurukuruIcon} />` + "<h3>位置情報を検索中...</h3>",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
            popup: styles.loadingPopup,
            htmlContainer: styles.htmlContainer,
        },
        didOpen: () => {
            if (navigator.onLine) {
                startTime = Date.now()
                navigator.geolocation.getCurrentPosition(successCallback, errorCallback, options)
            } else {
                noSignalPopup()
            }
        },
    })
}
