import Swal from "sweetalert2"
import styles from "./swalFires.module.scss"
import kurukuruIcon from "@/assets/kurukuruIcon.svg"
import { deleteUserPicture } from "@/repositories/userRepository"
import { axiosDelete } from "../../clients/httpClient"
import { getPreSignedUrlObjectForDeleteUserPicture } from "@/repositories/awsRepository"
import { Dispatch, SetStateAction } from "react"
import { UserPicture } from "../../contexts/AlbumContextProvider"

export const multiplePhotoDeletePopup = (
    userPictures: UserPicture[],
    setUserPictures: Dispatch<SetStateAction<UserPicture[]>>,
    numberOfSelectedPictures: number,
    setNumberOfSelectedPictures: (number: number) => void,
    setIsSelectMode: (isSelect: boolean) => void
) => {
    Swal.fire({
        html:
            `<h3>${numberOfSelectedPictures}枚の写真を<br/>削除しますか？</h3>` +
            "<div>" +
            "<button id='multiplePhotoDeletePopup_cancelButton'>キャンセル</button>" +
            "<button id='multiplePhotoDeletePopup_confirmButton'>削除</button>" +
            "</div>",
        showConfirmButton: false,
        allowOutsideClick: false,
        customClass: {
            popup: styles.multiplePhotoDeletePopup,
            htmlContainer: styles.htmlContainer,
        },
        didOpen: () => {
            const cancelButton = document.getElementById("multiplePhotoDeletePopup_cancelButton")
            cancelButton!.onclick = () => {
                Swal.clickCancel()
            }
            const confirmButton = document.getElementById("multiplePhotoDeletePopup_confirmButton")
            confirmButton!.onclick = () => {
                Swal.clickConfirm()
            }
        },
    }).then((result) => {
        if (result.isConfirmed) {
            Swal.fire({
                html: `<img alt='kurukuruIcon' src=${kurukuruIcon} />` + "<h3>写真削除中...</h3>",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                customClass: {
                    popup: styles.loadingPopup,
                    htmlContainer: styles.htmlContainer,
                },
            })
            ;(async () => {
                for (const picture of userPictures) {
                    if (picture.isSelect) {
                        const res = await getPreSignedUrlObjectForDeleteUserPicture(
                            picture.pictureId
                        )
                        await axiosDelete(res.deleteUrl)
                        await axiosDelete(res.deleteSinglePhotoUrl)
                        await axiosDelete(res.deleteOriginalUrl)
                        await deleteUserPicture(picture.historyOfVisitId, picture.fileName)

                        setUserPictures((prevState) =>
                            prevState.filter(
                                (oldPicture) => oldPicture.pictureId !== picture.pictureId
                            )
                        )
                    }
                }
                setIsSelectMode(false)
                setNumberOfSelectedPictures(0)
                Swal.close()
            })()
        }
    })
}
