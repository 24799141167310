import Swal from "sweetalert2"
import styles from "./swalFires.module.scss"
import kurukuruIcon from "@/assets/kurukuruIcon.svg"
import notificationOffIcon from "@/assets/myPage/notificationOffIcon.svg"
import { saveFcmToken } from "@/repositories/userRepository"
import { notificationErrorPopup } from "@/functions/swalPopups/notificationErrorPopup"

export const notificationDeniedPopup = (
    toggleNotificationDivRef: React.RefObject<HTMLDivElement>
) => {
    Swal.fire({
        html:
            "<h3>通知設定をオフにしますか？</h3>" +
            "<div>" +
            "<button id='notificationDeniedPopup_cancelButton'>キャンセル</button>" +
            "<button id='notificationDeniedPopup_confirmButton'>通知オフ</button>" +
            "</div>",
        showConfirmButton: false,
        allowOutsideClick: false,
        customClass: {
            popup: styles.notificationDeniedPopup,
            htmlContainer: styles.htmlContainer,
        },
        didOpen: () => {
            const cancelButton = document.getElementById("notificationDeniedPopup_cancelButton")
            cancelButton!.onclick = () => {
                Swal.clickCancel()
            }
            const confirmButton = document.getElementById("notificationDeniedPopup_confirmButton")
            confirmButton!.onclick = () => {
                Swal.clickConfirm()
            }
        },
    }).then((result) => {
        if (result.isConfirmed) {
            Swal.fire({
                html:
                    `<img alt='kurukuruIcon' src=${kurukuruIcon} />` +
                    "<h3>通知設定を変更中...</h3>",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                customClass: {
                    popup: styles.loadingPopup,
                    htmlContainer: styles.htmlContainer,
                },
            })
            saveFcmToken("notificationOff")
                .then(() => {
                    if (toggleNotificationDivRef.current) {
                        const img = toggleNotificationDivRef.current.children[0] as HTMLImageElement
                        img.alt = "notificationOffIcon"
                        img.src = notificationOffIcon
                        const p = toggleNotificationDivRef.current
                            .children[1] as HTMLParagraphElement
                        p.innerHTML = "通知オフ"
                    }
                    Swal.close()
                })
                .catch((error) => {
                    console.error(error)
                    notificationErrorPopup()
                })
        }
    })
}
