import styles from "./NotificationPage.module.scss"
import { axiosPost } from "../clients/httpClient"
import React, { useRef, useState } from "react"
import { csrfManager } from "../csrf/CsrfManager"
import { getToken } from "firebase/messaging"
import { messaging } from "../firebaseConfig"

export const NotificationPage = () => {
    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")
    const tokenRef = useRef<HTMLInputElement>(null)

    const getMyFcmToken = () => {
        window.Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
                getToken(messaging, {
                    vapidKey: import.meta.env.VITE_FCM_PUBLIC_KEY,
                }).then((token) => {
                    tokenRef.current!.value = token
                })
            }
        })
    }

    const postNotification = () => {
        const data = {
            title: title,
            content: content,
            token: tokenRef.current!.value,
        }
        axiosPost("/api/notification/send", data, {
            headers: {
                "X-CSRF-TOKEN": csrfManager.getCsrf(),
            },
        })
            .then((res) => {
                console.log(res)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    return (
        <div className={styles.notificationArea}>
            <h1>FCM通知ページ</h1>
            <div className={styles.titleArea}>
                <label>
                    通知タイトル(全角13文字くらいが表示限界)
                    <br />
                    現在{title.length}文字
                </label>
                <input
                    onChange={(e) => {
                        setTitle(e.target.value)
                    }}
                />
            </div>
            <div className={styles.detailArea}>
                <label>
                    通知内容(152文字まで)
                    <br />
                    現在{content.length}文字
                </label>
                <textarea
                    onChange={(e) => {
                        setContent(e.target.value)
                    }}
                    maxLength={152}
                />
            </div>
            <div className={styles.tokenArea}>
                <label>トークン個別送信 ※allと入力すると全員に送られます</label>
                <input ref={tokenRef} />
            </div>

            <div className={styles.buttonArea}>
                <button onClick={getMyFcmToken}>自分のfcmトークン取得</button>
                <button onClick={postNotification}>送信</button>
            </div>
        </div>
    )
}
