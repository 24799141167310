import { Jinja } from "@/repositories/jinjaRepository"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { cloneDeep } from "lodash"
import { selectedJinjaOfOverPopup } from "@/functions/swalPopups/selectedJinjaOfOverPopup"

interface JinjaMarkerProps extends google.maps.MarkerOptions {
    icon: string
    jinja: Jinja
    selectJinjaList: Jinja[]
    setSelectJinjaList: Dispatch<SetStateAction<Jinja[]>>
}

export const JinjaMarker: React.FC<JinjaMarkerProps> = (props: JinjaMarkerProps) => {
    const [marker, setMarker] = useState<google.maps.marker.AdvancedMarkerElement>(
        new google.maps.marker.AdvancedMarkerElement()
    )

    const clickActionJinjaMarker = () => {
        const foundSelectJinja = props.selectJinjaList.some((jinja) => {
            return jinja.jinjaId === props.jinja.jinjaId
        })
        if (!foundSelectJinja) {
            props.setSelectJinjaList((prevState) => {
                const newState = cloneDeep(prevState)
                newState.push(props.jinja)
                return newState
            })
        }
    }

    //markerが存在しなかったら作る、存在しててもアンマウントされたらmarkerを消す
    useEffect(() => {
        if (!marker) {
            setMarker(new google.maps.marker.AdvancedMarkerElement())
        }
        return () => {
            if (marker) {
                marker.map = null
            }
        }
    }, [marker])

    //markerのdesignの設定
    useEffect(() => {
        if (marker) {
            marker.onclick = () => {
                props.selectJinjaList.length === 9
                    ? selectedJinjaOfOverPopup()
                    : clickActionJinjaMarker()
            }
            marker.position = props.position
            marker.map = props.map as google.maps.Map
            const pin = document.createElement("img")
            pin.style.width = "28px"
            pin.style.height = "32px"
            pin.src = props.icon
            marker.content = pin
        }
    }, [marker, props])
    return null
}
