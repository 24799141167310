import jinja00 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000000.jpg"
import jinja01 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000001.jpg"
import jinja02 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000002.jpg"
import jinja03 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000003.jpg"
import jinja04 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000004.jpg"
import jinja05 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000005.jpg"
import jinja06 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000006.jpg"
import jinja07 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000007.jpg"
import jinja08 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000008.jpg"
import jinja09 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000009.jpg"
import jinja10 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000010.jpg"
import jinja11 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000011.jpg"
import jinja12 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000012.jpg"
import jinja13 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000013.jpg"
import jinja14 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000014.jpg"
import jinja15 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000015.jpg"
import jinja16 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000016.jpg"
import jinja17 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000017.jpg"
import jinja18 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000018.jpg"
import jinja19 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000019.jpg"
import jinja20 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000020.jpg"
import jinja21 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000021.jpg"
import jinja22 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000022.jpg"
import jinja23 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000023.jpg"
import jinja24 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000024.jpg"
import jinja25 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000025.jpg"
import jinja26 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000026.jpg"
import jinja27 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000027.jpg"
import jinja28 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000028.jpg"
import jinja29 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000029.jpg"
import jinja30 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000030.jpg"
import jinja31 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000031.jpg"
import jinja32 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000032.jpg"
import jinja33 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000033.jpg"
import jinja34 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000034.jpg"
import jinja35 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000035.jpg"
import jinja36 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000036.jpg"
import jinja37 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000037.jpg"
import jinja38 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000038.jpg"
import jinja39 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000039.jpg"
import jinja40 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000040.jpg"

export const jinjaImageList = [
    jinja00,
    jinja01,
    jinja02,
    jinja03,
    jinja04,
    jinja05,
    jinja06,
    jinja07,
    jinja08,
    jinja09,
    jinja10,
    jinja11,
    jinja12,
    jinja13,
    jinja14,
    jinja15,
    jinja16,
    jinja17,
    jinja18,
    jinja19,
    jinja20,
    jinja21,
    jinja22,
    jinja23,
    jinja24,
    jinja25,
    jinja26,
    jinja27,
    jinja28,
    jinja29,
    jinja30,
    jinja31,
    jinja32,
    jinja33,
    jinja34,
    jinja35,
    jinja36,
    jinja37,
    jinja38,
    jinja39,
    jinja40,
]
