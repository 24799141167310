import { UserPicture } from "../contexts/AlbumContextProvider"

export const convertToPicturesSortedByJinja = (pictures: UserPicture[]) => {
    const JinjaIds = pictures.map((picture) => picture.jinjaId)
    const filteredJinjaIds = Array.from(new Set(JinjaIds))

    return filteredJinjaIds
        .map((filteredJinjaId) =>
            pictures
                .filter((picture) => picture.jinjaId === filteredJinjaId)
                .sort((a, b) => b.registrationDate - a.registrationDate)
                .sort((a, b) => b.visitDate - a.visitDate)
        )
        .sort((a, b) => a[0].jinjaId.localeCompare(b[0].jinjaId))
}
