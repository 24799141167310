import "./App.css"
import { useEffect, useRef } from "react"
import { Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { registerSW } from "virtual:pwa-register"
import { AppCommon } from "./components/AppCommon"
import { serviceWorkerUpdatePopup } from "@/functions/swalPopups/serviceWorkerUpdatePopup"
import { AlbumPage } from "./pages/AlbumPage"
import { DownloadPage } from "./pages/DownloadPage"
import { EventPage } from "./pages/EventPage"
import { HomePage } from "./pages/HomePage"
import { JinjaDetailPage } from "./pages/JinjaDetailPage"
import { JinjaPhotoLibraryPage } from "./pages/JinjaPhotoLibraryPage"
import { MapPage } from "./pages/MapPage"
import { MyPage } from "./pages/MyPage"
import { TopPage } from "./pages/TopPage"
import { NotificationPage } from "./pages/NotificationPage"

function App() {
    const updateSWRef = useRef<() => void>()
    const location = useLocation()
    const navigate = useNavigate()
    useEffect(() => {
        updateSWRef.current = registerSW({
            onNeedRefresh: () => {
                serviceWorkerUpdatePopup(updateSWRef)
            },
        })
    }, [])
    useEffect(() => {
        if (location.pathname === "/app" || location.pathname === "/") {
            navigate("/app/top")
        }
    }, [navigate, location.pathname])

    return (
        <>
            <Routes>
                <Route path="/" element={<Outlet />}>
                    <Route path="app/top" element={<TopPage />} />
                    <Route path="app/download" element={<DownloadPage />} />
                    <Route path="app" element={<AppCommon />}>
                        <Route path="home" element={<HomePage />} />
                        <Route path="album" element={<AlbumPage />} />
                        <Route path="mypage" element={<MyPage />} />
                        <Route path="notification" element={<NotificationPage />} />
                        <Route path="jinja-detail/:jinjaId" element={<JinjaDetailPage />} />
                        <Route
                            path="jinja-detail/photo-library/:jinjaId"
                            element={<JinjaPhotoLibraryPage />}
                        />
                        <Route path="map" element={<MapPage />} />
                        <Route path="event" element={<EventPage />} />
                    </Route>
                </Route>
            </Routes>
        </>
    )
}

export default App
