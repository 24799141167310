import { axiosPost } from "../clients/httpClient"
import { csrfManager } from "../csrf/CsrfManager"

export type PostCheckinRequest = {
    longitude: number
    latitude: number
    checkinAcquisitionTime: number
    gpsAcquisitionTime: number
    jinjaDiffAcquisitionTime: number
}

export const postCheckin = (postCheckinRequest: PostCheckinRequest) => {
    axiosPost("/api/checkin", postCheckinRequest, {
        headers: {
            "X-CSRF-TOKEN": csrfManager.getCsrf(),
        },
    })
}
