import React, { useContext, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import disableAlbumIcon from "../../public/bottomNavigation/disableAlbumIcon.svg"
import disableEventIcon from "../../public/bottomNavigation/disableEventIcon.svg"
import enableEventIcon from "../../public/bottomNavigation/enableEventIcon.svg"
import disableHomeIcon from "../../public/bottomNavigation/disableHomeIcon.svg"
import disableMyPageIcon from "../../public/bottomNavigation/disableMyPageIcon.svg"
import enableAlbumIcon from "../../public/bottomNavigation/enableAlbumIcon.svg"
import enableCheckInIcon from "../../public/bottomNavigation/enableCheckInIcon.svg"
import disableCheckInIcon from "../../public/bottomNavigation/disableCheckInIcon.svg"
import enableHomeIcon from "../../public/bottomNavigation/enableHomeIcon.svg"
import enableMyPageIcon from "../../public/bottomNavigation/enableMyPageIcon.svg"
import disableTrashIcon from "@/assets/disableTrashIcon.svg"
import enableTrashIcon from "@/assets/enableTrashIcon.svg"
import { ApplicationContext } from "../contexts/ApplicationContextProvider"
import { isIphone } from "@/functions/isIphone"
import styles from "./BottomNavigation.module.scss"
import { photoDeletePopup } from "@/functions/swalPopups/photoDeletePopup"
import { AlbumContext } from "../contexts/AlbumContextProvider"
import { multiplePhotoDeletePopup } from "@/functions/swalPopups/multiplePhotoDeletePopup"
import { checkCurrentLocationPopup } from "@/functions/swalPopups/checkCurrentLocationPopup"

export const BottomNavigation = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const {
        isSelectMode,
        setIsSelectMode,
        numberOfSelectedPictures,
        setNumberOfSelectedPictures,
        singlePhotoViewMode,
        toursAreaRef,
        imageElementsRef,
        jinjaList,
        setHistories,
        newsListLength,
        readNewsList,
        setIsShowQuestionPopup,
        setIsShowAnswer1,
        setIsShowAnswer2,
        calenderSwiperRef,
        setSelectedDates,
    } = useContext(ApplicationContext)!
    const {
        isDaySort,
        setUserPictures,
        selectPictureIndex,
        userPicturesDaySort,
        userPicturesJinjaSort,
        setSelectPictureIndex,
    } = useContext(AlbumContext)!

    const buttonPattern: { [key: string]: boolean[] } = {
        home: [true, false, true, false, false],
        event: [false, true, true, false, false],
        "jinja-detail": [true, false, true, false, false],
        album: [false, false, true, true, false],
        mypage: [false, false, true, false, true],
        map: [true, false, true, false, false],
    }

    const decisionButtonPattern = () => {
        const pathName = location.pathname.split("/")[2]
        return buttonPattern[pathName] || []
    }

    const iconStates = useMemo(
        () => [
            {
                enabled: enableHomeIcon,
                disabled: disableHomeIcon,
                enabledAlt: "enableHomeIcon",
                disabledAlt: "disableHomeIcon",
            },
            {
                enabled: enableEventIcon,
                disabled: disableEventIcon,
                enabledAlt: "enableEventIcon",
                disabledAlt: "disableEventIcon",
            },
            {
                enabled: enableCheckInIcon,
                disabled: disableCheckInIcon,
                enabledAlt: "enableCheckInIcon",
                disabledAlt: "disableCheckInIcon",
            },
            {
                enabled: enableAlbumIcon,
                disabled: disableAlbumIcon,
                enabledAlt: "enableAlbumIcon",
                disabledAlt: "disableAlbumIcon",
            },
            {
                enabled: enableMyPageIcon,
                disabled: disableMyPageIcon,
                enabledAlt: "enableMyPageIcon",
                disabledAlt: "disableMyPageIcon",
            },
        ],
        []
    )

    const TrashIcon = () => {
        return (
            <div className={styles.selectedPicturesArea}>
                <img
                    src={enableTrashIcon}
                    className={styles.trashIcon}
                    alt="enableTrashIcon"
                    onClick={() => {
                        photoDeletePopup(
                            isDaySort ? userPicturesDaySort : userPicturesJinjaSort,
                            selectPictureIndex,
                            setUserPictures,
                            setSelectPictureIndex
                        )
                    }}
                />
            </div>
        )
    }

    const TrashIconWithPictureCount = () => {
        return (
            <div className={styles.selectedPicturesArea}>
                <p>{numberOfSelectedPictures}枚の写真を選択中</p>
                <button
                    className={styles.trashIcon}
                    onClick={() => {
                        multiplePhotoDeletePopup(
                            isDaySort ? userPicturesDaySort : userPicturesJinjaSort,
                            setUserPictures,
                            numberOfSelectedPictures,
                            setNumberOfSelectedPictures,
                            setIsSelectMode
                        )
                    }}
                >
                    <img src={enableTrashIcon} alt="enableTrashIcon" />
                </button>
            </div>
        )
    }

    const TrashIconWithNoPictures = () => {
        return (
            <div className={styles.selectedPicturesArea}>
                <p>項目を選択</p>
                <button disabled className={styles.trashIcon}>
                    <img src={disableTrashIcon} alt="disableTrashIcon" />
                </button>
            </div>
        )
    }

    const bottomNavButtonsClick = (clickButtonIndex: number, navigateTo: string) => {
        if (clickButtonIndex === 2) {
            checkCurrentLocationPopup(
                jinjaList,
                setHistories,
                imageElementsRef.current[2],
                navigate,
                setIsShowQuestionPopup,
                setIsShowAnswer1,
                setIsShowAnswer2
            )
        } else if (location.pathname === "/app/home" && clickButtonIndex === 0) {
            toursAreaRef.current!.scrollTo({
                top: 0,
                behavior: "smooth",
            })
        } else if (
            location.pathname === "/app/event" &&
            clickButtonIndex === 1 &&
            calenderSwiperRef.current
        ) {
            const unixTimeMillSec = Date.now()
            const timeToDate = new Date(unixTimeMillSec)
            const currentMonth = timeToDate.getMonth()
            const initialDates = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
            initialDates[currentMonth] = timeToDate.getDate()
            setSelectedDates(initialDates)
            calenderSwiperRef.current.slideTo(currentMonth - 5, 500)
        } else {
            navigate(navigateTo)
        }
    }

    return (
        <div
            className={`${styles.bottomNavigationArea} ${isIphone() ? styles.iphoneBottomNavigationArea : ""}`}
        >
            {singlePhotoViewMode === "album" ? (
                <TrashIcon />
            ) : isSelectMode ? (
                numberOfSelectedPictures ? (
                    <TrashIconWithPictureCount />
                ) : (
                    <TrashIconWithNoPictures />
                )
            ) : (
                <>
                    {iconStates.map((iconState, index) => (
                        <button key={`bottomNavButton${index}`}>
                            <img
                                ref={(element) =>
                                    (imageElementsRef.current[index] = element as HTMLImageElement)
                                }
                                onClick={() => {
                                    const navigateName = Object.keys(buttonPattern)[index]
                                    bottomNavButtonsClick(index, `/app/${navigateName}`)
                                }}
                                className={index === 2 ? styles.enableCheckInIcon : ""}
                                src={
                                    decisionButtonPattern()[index]
                                        ? iconState.enabled
                                        : iconState.disabled
                                }
                                alt={
                                    decisionButtonPattern()[index]
                                        ? iconState.enabledAlt
                                        : iconState.disabledAlt
                                }
                            />
                        </button>
                    ))}
                    {newsListLength > readNewsList.filter((value) => value).length && (
                        <div className={styles.newsIcon}></div>
                    )}
                </>
            )}
        </div>
    )
}
