import { useEffect, useState } from "react"

interface CurrentLocationMarkerProps extends google.maps.MarkerOptions {
    icon: string
}

export const CurrentLocationMarker: React.FC<CurrentLocationMarkerProps> = (
    props: CurrentLocationMarkerProps
) => {
    const [marker, setMarker] = useState<google.maps.marker.AdvancedMarkerElement>(
        new google.maps.marker.AdvancedMarkerElement()
    )

    //markerが存在しなかったら作る、存在しててもアンマウントされたらmarkerを消す
    useEffect(() => {
        if (!marker) {
            setMarker(new google.maps.marker.AdvancedMarkerElement())
        }
        return () => {
            if (marker) {
                marker.map = null
            }
        }
    }, [marker])

    //markerのdesignの設定
    useEffect(() => {
        marker.position = props.position
        marker.map = props.map as google.maps.Map
        const pin = document.createElement("img")
        pin.src = props.icon
        marker.content = pin
    }, [marker, props])
    return null
}
