import { Calendar } from "@/components/Calendar"
import { useContext, useEffect, useRef } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import styles from "./EventPage.module.scss"
import bottomStyles from "@/components/BottomNavigation.module.scss"
import { EventDetail } from "@/components/EventDetail"
import { eventPageService } from "@/services/EventPageService"
import { ApplicationContext } from "../contexts/ApplicationContextProvider"

export const EventPage = () => {
    const now = new Date()
    const currentMonth = now.getMonth()
    const currentDate = now.getDate()
    const initialSelectedDates = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
    initialSelectedDates[currentMonth] = currentDate
    const eventDetailViewAreaRef = useRef<HTMLDivElement | null>(null)
    const prevSelectedDatesRef = useRef<number[]>([])

    const { month, setMonth, selectedDates, setSelectedDates, calenderSwiperRef } =
        useContext(ApplicationContext)!

    useEffect(() => {
        sessionStorage.setItem("scrollPosition", "0")
    }, [])
    return (
        <div className={`${styles.eventPageArea}`}>
            <header>
                <h2>{month + 1}月</h2>
                <p>2024</p>
            </header>
            <div className={styles.calendarArea}>
                <Swiper
                    onBeforeInit={() => {
                        prevSelectedDatesRef.current = selectedDates
                    }}
                    onSwiper={(swiper) => {
                        calenderSwiperRef.current = swiper
                    }}
                    className={styles.swiperArea}
                    pagination={{ clickable: true, dynamicBullets: true }}
                    slidesPerView={1}
                    initialSlide={month - 5}
                    onSlideChange={({ activeIndex }) => {
                        const monthIndex = activeIndex + 5
                        setSelectedDates(initialSelectedDates)
                        setMonth(monthIndex)
                        if (eventDetailViewAreaRef.current) {
                            if (monthIndex === 8 || monthIndex === 11) {
                                eventDetailViewAreaRef.current.className = `${styles.eventDetailViewArea} ${styles.down5to6Anime}`
                            } else {
                                if (
                                    !eventDetailViewAreaRef.current?.className.includes(
                                        "down5Anime"
                                    )
                                ) {
                                    eventDetailViewAreaRef.current.className = `${styles.eventDetailViewArea} ${styles.up6to5Anime}`
                                }
                            }
                        }
                    }}
                    onAfterInit={() => {
                        setSelectedDates(prevSelectedDatesRef.current)
                    }}
                >
                    {new Array(12).fill(undefined).map((_, index) => {
                        if (index >= 5)
                            return (
                                <SwiperSlide key={`swiperSlide${index}`}>
                                    <Calendar
                                        key={index}
                                        month={index}
                                        now={now}
                                        selectedDate={selectedDates[index]}
                                    />
                                </SwiperSlide>
                            )
                    })}
                </Swiper>
            </div>
            <div
                ref={eventDetailViewAreaRef}
                className={`${styles.eventDetailViewArea} ${month === 8 || month === 11 ? styles.down5to6Anime : styles.up6to5Anime}`}
                onTouchStart={(e) => {
                    eventPageService.setProperty(e)
                }}
                onTouchMove={(e) => {
                    const bottomNavigationHeight = document.getElementsByClassName(
                        bottomStyles.bottomNavigationArea
                    )[0].clientHeight
                    eventPageService.changeStyleAndSetPositionY(e, month, bottomNavigationHeight)
                }}
                onTouchEnd={(e) => {
                    eventPageService.setAnimeClass(e, month)
                }}
            >
                <div>
                    <hr />
                </div>
                <EventDetail selectedDateByEachMonth={selectedDates} month={month} />
            </div>
        </div>
    )
}
