import { Area } from "react-easy-crop"
import { updateUserIcon } from "@/repositories/userRepository"
import {
    getPreSignedUrlObjectForGetUserIcon,
    getPreSignedUrlObjectForPutUserIcon,
} from "@/repositories/awsRepository"
import { axiosPut } from "../clients/httpClient"

export const getAndSaveCroppedImg = async (dataURL: string, pixelCrop: Area) => {
    const image = document.createElement("img")
    image.src = dataURL
    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")

    canvas.setAttribute("width", image.width.toString())
    canvas.setAttribute("height", image.height.toString())

    ctx!.drawImage(image, 0, 0)

    const data = ctx!.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height)

    canvas.setAttribute("width", pixelCrop.width.toString())
    canvas.setAttribute("height", pixelCrop.height.toString())

    ctx!.putImageData(data, 0, 0)

    const newCanvas = document.createElement("canvas")
    newCanvas.width = 300
    newCanvas.height = 300
    const newContext = newCanvas.getContext("2d")
    newContext!.drawImage(canvas, 0, 0, 300, 300)

    const newDataURL = newCanvas.toDataURL("image/png")

    return await fetch(newDataURL)
        .then((res) => {
            return res.arrayBuffer()
        })
        .then((buf) => {
            return new File([buf], "userIcon.png", { type: "image/png" })
        })
        .then(async (file) => {
            const obj = await getPreSignedUrlObjectForPutUserIcon()
            await axiosPut(obj.putUrl, file)
            await updateUserIcon()
            return await getPreSignedUrlObjectForGetUserIcon()
        })
        .catch((error) => console.log(error))
}
