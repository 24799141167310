import upArrow from "@/assets/myPage/upArrow.svg"
import { useContext, useState } from "react"
import styles from "./NewsView.module.scss"
import { ApplicationContext } from "../contexts/ApplicationContextProvider"
import { cloneDeep } from "lodash"
import { withToastPopup } from "../enhance/withToastPopup"

type NewsViewProps = {
    setIsShowPopup: (compareStartWithEnd: boolean) => void
    isNews: boolean
}

export const NewsView = () => {
    const [isShowNews1, setIsShowNews1] = useState(false)
    const [isShowNews2, setIsShowNews2] = useState(false)
    const [isShowNews3, setIsShowNews3] = useState(false)
    const [isShowNews4, setIsShowNews4] = useState(false)
    const [isShowNews5, setIsShowNews5] = useState(false)
    const [isShowNews6, setIsShowNews6] = useState(false)
    const [isShowNews7, setIsShowNews7] = useState(false)
    const { readNewsList, setReadNewsList } = useContext(ApplicationContext)!

    const setReadNewsListCallback = (prevState: boolean[], index: number) => {
        const newState = cloneDeep(prevState)
        newState[index] = true
        for (let i = 0; i < newState.length; i++) {
            if (!Object.prototype.hasOwnProperty.call(newState, i)) {
                newState[i] = false
            }
        }
        localStorage.setItem("readNewsList", JSON.stringify(newState))
        return newState
    }

    return (
        <div className={styles.newsViewArea}>
            <hr />
            <div
                className={styles.newsTitleArea}
                onClick={() => {
                    setIsShowNews7((prevState) => !prevState)
                    setReadNewsList((prevState) => setReadNewsListCallback(prevState, 6))
                }}
            >
                {!readNewsList[6] && <div className={styles.newsIcon}></div>}
                <p>11/12 アップデートのお知らせ</p>
                <img
                    src={upArrow}
                    alt="upArrow"
                    className={isShowNews7 ? styles.downArrowStyle : styles.upArrowStyle}
                />
            </div>
            {isShowNews7 && (
                <div className={styles.newsDetailArea}>
                    <ul>
                        <li>
                            神社お気に入り機能の追加
                            <br />
                            星マークをタップすることで気になった神社に印をつけることができます
                            <br />
                            この機能は、新たに追加した神社情報ソート機能でも活用できます
                            <br />
                            お気に入りは星マークをタップすることで切り替えられます
                        </li>
                        <br />
                        <li>
                            神社情報ソート機能の追加
                            <br />
                            ホーム画面でお気に入り、参拝履歴、御朱印、お手洗い、無料駐車場の有無で表示する神社を切り替えられます
                            <br />
                        </li>
                        <br />
                        <li>
                            経路検索機能の追加
                            <br />
                            ホーム画面の「経路作成」ボタンから機能に入れます
                            <br />
                            「絞り込み」ボタンで好みの神社に絞ることができます
                            <br />
                            <br />
                            {"<経路作成方法>"}
                            <br />
                            1. 地図上で参拝したい神社を複数選択(最大９箇所)
                            <br />
                            2. 「経路検索」ボタンをタップ
                            <br />
                            3. 複数神社を結んだ経路が表示
                            <br />
                        </li>
                    </ul>
                    <br />
                    <p>これらの機能が皆様の参拝体験を手助けできることを願っています</p>
                    <br />
                    <p>ぜひ、ご利用下さい！</p>
                </div>
            )}
            <hr />
            <div
                className={styles.newsTitleArea}
                onClick={() => {
                    setIsShowNews6((prevState) => !prevState)
                    setReadNewsList((prevState) => setReadNewsListCallback(prevState, 5))
                }}
            >
                {!readNewsList[5] && <div className={styles.newsIcon}></div>}
                <p>10/17 アップデートのお知らせ</p>
                <img
                    src={upArrow}
                    alt="upArrow"
                    className={isShowNews6 ? styles.downArrowStyle : styles.upArrowStyle}
                />
            </div>
            {isShowNews6 && (
                <div className={styles.newsDetailArea}>
                    <p>神社の詳細ページに周辺の観光情報を検索できるようリンクを追加しました</p>
                </div>
            )}
            <hr />
            <div
                className={styles.newsTitleArea}
                onClick={() => {
                    setIsShowNews5((prevState) => !prevState)
                    setReadNewsList((prevState) => setReadNewsListCallback(prevState, 4))
                }}
            >
                {!readNewsList[4] && <div className={styles.newsIcon}></div>}
                <p>10/11 アップデートのお知らせ</p>
                <img
                    src={upArrow}
                    alt="upArrow"
                    className={isShowNews5 ? styles.downArrowStyle : styles.upArrowStyle}
                />
            </div>
            {isShowNews5 && (
                <div className={styles.newsDetailArea}>
                    <ul>
                        <li>イベントページの操作性改善</li>
                        <br />
                        <li>
                            神社情報ページの操作性改善
                            <br />
                            ページ下端の関連神社名からその神社情報に飛べます
                        </li>
                        <br />
                        <li>写真読み込み時のパフォーマンス改善</li>
                        <br />
                        <li>
                            神社のチェックイン範囲見直し
                            <br />
                            対象神社：神宮・皇大神宮（内宮）、倭姫宮、篠畑神社、神山神社、瀧原宮
                        </li>
                        <br />
                        <li>
                            参拝で得られるバッジ種類の追加
                            <br />
                            {"<獲得方法>"}
                            <br />
                            各宮地の関連神社で全てチェックインすることでバッジが獲得できます
                            <br />
                            <br />
                            「マイページ」の「集めたバッジ」からバッジと共に参拝の記録を振り返りましょう
                        </li>
                    </ul>
                    <br />
                    <p>ぜひ、ご利用下さい！</p>
                </div>
            )}
            <hr />
            <div
                className={styles.newsTitleArea}
                onClick={() => {
                    setIsShowNews4((prevState) => !prevState)
                    setReadNewsList((prevState) => setReadNewsListCallback(prevState, 3))
                }}
            >
                {!readNewsList[3] && <div className={styles.newsIcon}></div>}
                <p>9/27 神社情報修正のお知らせ</p>
                <img
                    src={upArrow}
                    alt="upArrow"
                    className={isShowNews4 ? styles.downArrowStyle : styles.upArrowStyle}
                />
            </div>
            {isShowNews4 && (
                <div className={styles.newsDetailArea}>
                    <p>
                        下記神社の修正を行いました
                        <br />
                        <br />
                        対象神社：阿射坂神社-大阿坂、阿射坂神社-小阿坂
                    </p>
                </div>
            )}
            <hr />
            <div
                className={styles.newsTitleArea}
                onClick={() => {
                    setIsShowNews3((prevState) => !prevState)
                    setReadNewsList((prevState) => setReadNewsListCallback(prevState, 2))
                }}
            >
                {!readNewsList[2] && <div className={styles.newsIcon}></div>}
                <p>9/20 アプリ名称変更のお知らせ</p>
                <img
                    src={upArrow}
                    alt="upArrow"
                    className={isShowNews3 ? styles.downArrowStyle : styles.upArrowStyle}
                />
            </div>
            {isShowNews3 && (
                <div className={styles.newsDetailArea}>
                    <p>いつもアプリをお楽しみ頂きありがとうございます</p>
                    <br />
                    <p>
                        この度、御朱印帳との連携を強化し、サービスの一体感を高めるために
                        <br />
                        アプリの名称を『倭姫命の御朱印巡り』に変更いたしました
                    </p>
                    <br />
                    <p>利用規約の上記に関する部分について変更を行いました</p>
                    <br />
                    <p>アプリはこれまでと同様にお使いいただけます</p>
                    <br />
                    <p>引き続きアプリをお楽しみください</p>
                </div>
            )}
            <hr />
            <div
                className={styles.newsTitleArea}
                onClick={() => {
                    setIsShowNews2((prevState) => !prevState)
                    setReadNewsList((prevState) => setReadNewsListCallback(prevState, 1))
                }}
            >
                {!readNewsList[1] && <div className={styles.newsIcon}></div>}
                <p>9/20 アップデートのお知らせ</p>
                <img
                    src={upArrow}
                    alt="upArrow"
                    className={isShowNews2 ? styles.downArrowStyle : styles.upArrowStyle}
                />
            </div>
            {isShowNews2 && (
                <div className={styles.newsDetailArea}>
                    <p>
                        いつもアプリをお楽しみ頂きありがとうございます
                        <br />
                        以下のアップデートを行いました
                    </p>
                    <br />
                    <ul>
                        <li>
                            お知らせページ（本ページ）を新たに追加しました
                            <br />
                            今後のアップデート内容は本ページを確認ください
                        </li>
                        <br />
                        <li>
                            以下の神社情報の修正、更新を行いました
                            <br />
                            篠畑神社、葛神社、御杖神社、敢国神社、坂田神明宮、宇波刀神社、野志里神社、加良比乃神社、磯神社
                        </li>
                        <br />
                        <li>
                            チェックインした際に、初めて訪れた神社に対して参拝神社数が表示されるようになりました
                        </li>
                        <br />
                        <li>
                            より快適でスムーズな体験を提供するために、Web版からダウンロード版をご利用頂けるよう以下の変更を行いました
                            <br />
                            iPhoneの方：ログインページに設定方法を表示
                            <br />
                            Androidの方：ダウンロード版をご利用頂けるようメッセージを表示
                            <br />
                            <br />
                            御朱印帳に挟まれているパンフレットの裏にも設定方法が記載されていますのでご確認ください
                            <br />
                            （ダウンロード版をご利用の方は表示されません）
                        </li>
                    </ul>
                    <br />
                    <p>ご不明な点がございましたら、お気軽にお問い合わせください</p>
                    <br />
                    <p>引き続きアプリをお楽しみください</p>
                </div>
            )}
            <hr />
            <div
                className={styles.newsTitleArea}
                onClick={() => {
                    setIsShowNews1((prev) => !prev)
                    setReadNewsList((prevState) => setReadNewsListCallback(prevState, 0))
                }}
            >
                {!readNewsList[0] && <div className={styles.newsIcon}></div>}
                <p>9/7 アップデートのお知らせ</p>
                <img
                    src={upArrow}
                    alt="upArrow"
                    className={isShowNews1 ? styles.downArrowStyle : styles.upArrowStyle}
                />
            </div>
            {isShowNews1 && (
                <div className={styles.newsDetailArea}>
                    <p>アルバムページに保存した写真を共有できるようになりました</p>
                    <br />
                    <p>{"<使い方>"}</p>
                    <ul>
                        <li>アルバムページから＋ボタンを押して、写真を保存</li>
                        <br />
                        <li>保存した写真を１枚タップして表示する</li>
                        <br />
                        <li>右上の「公開」ボタンから共有できます</li>
                        <br />
                        <li>公開した写真は、各神社のページで見ることができます</li>
                        <br />
                        <li>みなさんの素敵な思い出や神社の魅力を共有しましょう</li>
                    </ul>
                    <br />
                    <p>ぜひ、ご利用下さい！</p>
                </div>
            )}
            <hr />
        </div>
    )
}

export const NewsViewWithToastPopup = withToastPopup<NewsViewProps>(NewsView)
