import {
    getPreSignedUrlObjectsForGetAllUserPublishPictures,
    getPreSignedUrlObjectsForGetJinjaPictures,
    ResponseGetPicture,
} from "@/repositories/awsRepository"
import { UserPicture } from "../contexts/AlbumContextProvider"

export const convertJinjaPictureAndUserPicture = (
    jinjaId: string | undefined,
    setJinjaPictures: (jinjaPictures: UserPicture[]) => void
) => {
    if (jinjaId) {
        ;(async () => {
            const jinjaPictures: ResponseGetPicture[] =
                await getPreSignedUrlObjectsForGetJinjaPictures(jinjaId)
            const allUserPictures: ResponseGetPicture[] =
                await getPreSignedUrlObjectsForGetAllUserPublishPictures(jinjaId)
            const newJinjaPicture: UserPicture[] = [...jinjaPictures, ...allUserPictures].map(
                (responseGetPicture) => {
                    const newResponseGetPicture: UserPicture = {
                        ...responseGetPicture,
                        isSelect: false,
                    }
                    return newResponseGetPicture
                }
            )

            setJinjaPictures(newJinjaPicture)
        })()
    }
}
