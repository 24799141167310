import Swal from "sweetalert2"
import errorIcon from "@/assets/errorIcon.svg"
import styles from "./swalFires.module.scss"

export const noCurrentLocationPopup = () => {
    Swal.fire({
        html:
            `<img alt="errorIcon" src=${errorIcon} />` +
            `<h3>現在地を取得できません</h3>` +
            `<p>絞り込み機能の一部が<br/>正常に動作しない可能性があります</p>` +
            `<button class=${styles.closeButton} id="noCurrentLocationPopup_closeButton">閉じる</button>`,
        showConfirmButton: false,
        allowOutsideClick: false,
        customClass: {
            popup: styles.noCurrentLocationPopup,
            htmlContainer: styles.htmlContainer,
        },
        didOpen: () => {
            const closeButton = document.getElementById("noCurrentLocationPopup_closeButton")
            closeButton!.onclick = () => {
                Swal.close()
            }
        },
    })
}
