import Swal from "sweetalert2"
import styles from "./swalFires.module.scss"
import kurukuruIcon from "@/assets/kurukuruIcon.svg"
import { deleteUserPicture } from "@/repositories/userRepository"
import { axiosDelete } from "../../clients/httpClient"
import { getPreSignedUrlObjectForDeleteUserPicture } from "@/repositories/awsRepository"
import { Dispatch, SetStateAction } from "react"
import { UserPicture } from "../../contexts/AlbumContextProvider"

export const photoDeletePopup = (
    pictures: UserPicture[],
    pictureIndex: number,
    setPictures: Dispatch<SetStateAction<UserPicture[]>>,
    setSelectPictureIndex: Dispatch<SetStateAction<number>>
) => {
    Swal.fire({
        html:
            "<h3>削除しますか？</h3>" +
            "<div>" +
            "<button id='photoDeletePopup_cancelButton'>キャンセル</button>" +
            "<button id='photoDeletePopup_confirmButton'>削除</button>" +
            "</div>",
        showConfirmButton: false,
        allowOutsideClick: false,
        customClass: {
            popup: styles.photoDeletePopup,
            htmlContainer: styles.htmlContainer,
        },
        didOpen: () => {
            const cancelButton = document.getElementById("photoDeletePopup_cancelButton")
            cancelButton!.onclick = () => {
                Swal.clickCancel()
            }
            const confirmButton = document.getElementById("photoDeletePopup_confirmButton")
            confirmButton!.onclick = () => {
                Swal.clickConfirm()
            }
        },
    }).then(async (result) => {
        if (result.isConfirmed) {
            Swal.fire({
                html: `<img alt='kurukuruIcon' src=${kurukuruIcon} />` + "<h3>写真削除中...</h3>",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                customClass: {
                    popup: styles.loadingPopup,
                    htmlContainer: styles.htmlContainer,
                },
            })
            const res = await getPreSignedUrlObjectForDeleteUserPicture(
                pictures[pictureIndex].pictureId
            )
            await axiosDelete(res.deleteUrl)
            await axiosDelete(res.deleteSinglePhotoUrl)
            await axiosDelete(res.deleteOriginalUrl)
            await deleteUserPicture(
                pictures[pictureIndex].historyOfVisitId,
                pictures[pictureIndex].fileName
            )
            const newPictures = pictures.filter((picture) => {
                return picture.pictureId !== pictures[pictureIndex].pictureId
            })
            if (pictures.length - 1 === pictureIndex)
                setSelectPictureIndex((prevState) => prevState - 1)
            setPictures(newPictures)
            Swal.close()
        }
    })
}
