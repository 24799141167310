import "swiper/css"
import "swiper/css/pagination"
import "./Swiper.scss"
import { useEffect } from "react"
import { saveFcmToken, updateUserStatus } from "@/repositories/userRepository"
import { CloseIcon } from "./icon/CloseIcon"
import styles from "./SummaryPopup.module.scss"
import { getToken } from "firebase/messaging"
import { messaging } from "../firebaseConfig"

type Props = {
    setIsPopupSummary: (isPopupSummary: boolean) => void
}

export const SummaryPopup = (props: Props) => {
    useEffect(() => {
        updateUserStatus()
    }, [])

    const requestNotificationPermission = () => {
        window.Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
                getToken(messaging, { vapidKey: import.meta.env.VITE_FCM_PUBLIC_KEY }).then(
                    (token) => {
                        saveFcmToken(token)
                    }
                )
            }
        })
    }

    return (
        <div className={styles.modalArea}>
            <div className={styles.popupArea}>
                <div className={styles.popupTitle}>
                    <h1>
                        <ruby data-ruby="やまとひめのみこと">倭姫命の御巡行とは</ruby>
                    </h1>
                    <button
                        onClick={() => {
                            props.setIsPopupSummary(false)
                            requestNotificationPermission()
                        }}
                    >
                        <CloseIcon />
                    </button>
                </div>
                <div className={styles.story}>
                    <p>物語の主人公、</p>
                    <p>倭姫命は、およそ2千年前の</p>
                    <p>第11代垂仁天皇の皇女です。</p>
                    <br />
                    <p>天照大御神</p>
                    <p>（あまてらすおおみかみ）の</p>
                    <p>永久の鎮座地を求め、</p>
                    <p>5名の秀でた守り人とともに</p>
                    <p>大和の国から太陽の昇る東を目指した</p>
                    <p>倭姫命一行は、40余年の旅路を経て</p>
                    <p>伊勢にたどり着きます。</p>
                    <br />
                    <p>その時、天照大御神より</p>
                    <p>「この国に居らんとおもう」という</p>
                    <p>お告げを受け、社を建て</p>
                    <p>お祀（まつ）りされたことが</p>
                    <p>神宮のはじまりとされています。</p>
                    <br />
                    <p>長い旅路の途中、</p>
                    <p>一行が滞在し大御神を</p>
                    <p>お祀りしたとされるお宮の場所は、</p>
                    <p>複数の神社や遺跡が</p>
                    <p>「比定地」として</p>
                    <p>推定されています。</p>
                    <br />
                    <p>この御巡行路は、</p>
                    <p>その代表的な比定地からなる、</p>
                    <p>奈良、滋賀、岐阜、愛知、三重の</p>
                    <p>5県にまたがるルートを</p>
                    <p>ご紹介するものです。</p>
                    <br />
                    <p>本アプリでは、御朱印帳に</p>
                    <p>掲載されていない</p>
                    <p>比定地もありますので、</p>
                    <p>御朱印帳の掲載地とともに</p>
                    <p>参拝いただければ幸いです。</p>
                    <br />
                    <p>参考文献：「倭姫命伝記」より</p>
                </div>
            </div>
        </div>
    )
}
